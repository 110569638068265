.mainget {
    .cardparents {

        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        gap: 15px;

        .parentcardssss {
            background: #0B0B0B;
            padding: 66px 85px 117px 71px;
            width: 100%;
            height: 673px;

            h2 {
                color: #FFF;

                font-feature-settings: 'clig' off, 'liga' off;
                font-family: "Space Mono";
                font-size: 45px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                /* 54px */
                text-transform: uppercase;
            }

            p {
                color: #9A9A9A;

                font-feature-settings: 'clig' off, 'liga' off;
                font-family: "Space Mono";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                /* 16.8px */
                text-transform: capitalize;
                margin-top: 30px;
                margin-bottom: 60px;
            }

            .twicebtns {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 20px;

                .btnwhite {
                    border: 1px solid #FFF;

                    background: #0B0B0B;

                    box-shadow: 2px 2px 0px 0px #FFF;
                    padding: 0px;
                    max-width: fit-content;
                    display: flex;
                    align-items: center;
                    flex-direction: row;

                    width: 100%;

                    .image {
                        border-left: 1px solid #fff;
                        display: flex;
                        padding: 13px 20px;
                        justify-content: center;
                        align-items: center;
                    }

                    .btntext {
                        color: #FFF;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: uppercase;
                        // display: flex;
                        padding: 7px 30px;
                        justify-content: center;
                        align-items: center;
                    }

                }
            }
        }

        .parentcardsss {
            background: #0B0B0B;
            padding: 66px 85px 117px 71px;
            width: 100%;

            .btnwhite {
                border: 1px solid #0B0B0B;

                background: #fff;

                box-shadow: 2px 2px 0px 0px #FFF;
                padding: 0px;
                max-width: fit-content;
                display: flex;
                align-items: center;
                flex-direction: row;

                width: 100%;

                .image {
                    border-left: 1px solid #000;
                    display: flex;
                    padding: 13px 20px;
                    justify-content: center;
                    align-items: center;
                }

                .btntext {
                    color: #0B0B0B;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: uppercase;
                    display: flex;
                    padding: 7px 30px;
                    justify-content: center;
                    align-items: center;
                    width: 548px;
                }

            }

            textarea {
                border: 1px solid #2C2C2C;
                margin-bottom: 12px;
                background: #0B0B0B;
                padding: 20px;
                height: 143px;
                width: 100%;
                color: #fff;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                &::placeholder {
                    color: #767676;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: uppercase;

                }
            }

            h2 {
                color: #FFF;
                font-size: 45px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                /* 54px */
                text-transform: uppercase
            }

            p {
                color: #9A9A9A;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                /* 16.8px */
                text-transform: capitalize;
                margin-top: 20px;
                margin-bottom: 50px;
            }

            input {
                border: 1px solid #2C2C2C;
                margin-bottom: 12px;
                background: #0B0B0B;
                padding: 20px;
                width: 100%;
                color: #fff;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                &::placeholder {
                    color: #767676;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                }
            }

            .twiceinputs {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 12px;
                margin-top: 12px;

            }
        }
    }
}

@media(max-width:1024px) {
    .mainget .cardparents {
        flex-direction: column;
    }

    .mainget .cardparents .parentcardsss .btnwhite .btntext {
        width: 1024px;
    }

    .mainget .cardparents .parentcardssss .twicebtns {
        flex-direction: column;
    }
}

@media(max-width:600px) {
    .mainget .cardparents .parentcardsss {
        padding: 65px 0px;
    }

    .mainget .cardparents .parentcardsss h2 {
        font-size: 20px !important;
    }

    .mainget .cardparents .parentcardssss h2 {
        font-size: 20px !important;
    }

    .mainget .cardparents .parentcardssss {
        padding: 65px 15px;
    }

    .twicebtns .btnwhite .btntext {
        padding: 7px 8px !important;
    }

    .mainget .cardparents .parentcardssss .twicebtns {
        flex-direction: row;
    }

    .mainget .cardparents .parentcardssss .twicebtns .btnwhite .btntext {
        font-size: 14px;
    }

    .mainget .cardparents .parentcardsss {
        padding: 65px 15px;
    }

    .mainget .cardparents .parentcardssss .twicebtns {
        justify-content: space-between;
    }

    .mainget .cardparents .parentcardssss .twicebtns .btnwhite {
        max-width: 48%;
    }

    .mainget .cardparents .parentcardssss .twicebtns .btnwhite .btntext {
        flex: 1;
    }

    .mainget .cardparents .parentcardssss .twicebtns .btnwhite .image {
        width: 60px;
    }

    .mainget .cardparents .parentcardssss {
        height: auto;
    }

    .mainfaqs .upperhead .headinggggg {
        width: 100% !important;
    }

    .mainfaqs .acc .accordion .accordion-item .accordion-header .accordion-button {
        font-size: 14px;
    }
}



.contact-modal {
    .modal-dialog {
        max-width: 418px;
    }

    .modal-content {
        border: 1px solid #727272;
        background: #000;
        padding: 20px 20px 50px 20px;
    }

    .modal-header {
        border: 1px solid #3A3A3A;
        background: rgba(0, 0, 0, 0.70);

        .modal-title {
            color: #FFF;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
        }

        .btn-close {
            background: url("../../../assets/close-btn-modal.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            opacity: 1;
            box-shadow: none;
            border-radius: 0;
        }
    }

    .modal-body {
        padding: 0;
        padding-top: 50px;
        img{
            display: block;
            margin: 0 auto;
        }

        h6 {
            color: #FFF;
            text-align: center;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            margin-top: 50px;
            margin-bottom: 20px;
        }

        p {
            color: #9A9A9A;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
        }
    }
}

@media (max-width:600px){
    .contact-modal .modal-body h6{
        font-size: 26px;
    }
}