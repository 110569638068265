.stats-section {
    padding-bottom: 161px;
    background: #0B0B0B;
    position: relative;
    z-index: 99;
    .stats-bg{
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;
        pointer-events: none;
    }
    .top-left-text {
        border-bottom: 1px solid #1F1F1F;
        background: #0B0B0B;
        padding-left: 30px;
        height: 69px;
        display: flex;
        align-items: center;
        margin-bottom: 109px;

        p {
            color: #6C6C6C;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.014px;
            text-transform: uppercase;
        }
    }

    .main-heading{
        max-width: 642px;
        width: 100%;
        margin: 0 auto;
        h6{
            color: #FFF;
            text-align: center;
            font-size: 50px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            margin-bottom: 36px;
        }
        p{
            color: #9A9A9A;
            text-align: center;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; 
        }
    }
}


@media (max-width:1024px){
    .stats-section .main-heading h6{
        font-size: 37px;
    }
    .stats-section .main-heading p{
        font-size: 18px;
    }
    .stats-section{
        padding-bottom: 218px;
    }
    .stats-section .stats-bg{
        width: 238px;
        object-fit: cover;
    }
}