.dopphases {
    background: #0B0B0B;
    // opacity: 0;
    overflow: hidden;
    // visibility: hidden;
    // transition: opacity 3s;
    // padding-bottom: 147px;
    margin-bottom: 15px;
    // height: 0;
    // width: 0;
    // &.activee{
    //     opacity: 1;
    //     visibility: visible;
    //     width: 100%;
    //     height: 100%;

    // }

    .main-heading {
        margin-top: 55px;

        h4 {
            color: #FFF;
            font-size: 45px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            text-align: center;
            max-width: 900px;
            margin: 0 auto;
        }
    }

    .dopphasesheader {
        height: 69px;
        border-bottom: 1px solid #1F1F1F;
        background: #0B0B0B;
        display: flex;
        align-items: center;
        padding: 0px 30px;

        .dopphasesheaderpara {
            color: #7E7E7E;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.014px;
            text-transform: uppercase;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 7px;
        }
    }

    .dopphasesinner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        gap: 20px;

        .dopphasesleft {
            max-width: 539px;
            width: 100%;

            .dopphaseslefthead {
                color: #FFF;
                font-size: 34px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                margin-bottom: 50px;
                text-transform: uppercase;
            }

            .dopphasesleftpara {
                color: #9A9A9A;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                margin-bottom: 20px;
            }
        }
    }

    .tab-phases {
        max-width: 564px;
        width: 100%;
        margin: 0 auto;
        border: 1px solid #2C2C2C;
        background: #0B0B0B;
        margin-top: 70px;
        justify-content: space-between;
        .nav-item{
            flex: 1;
            .nav-link{
                border: 1px solid #2C2C2C;
                background: #0B0B0B;
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%; 
                text-transform: uppercase;
                width: 100%;
                padding: 18px 12px;
                border-radius: 0;
                // box-shadow: 2px 2px 0px 0px #FFF;
                
            }
        }
         .nav-item.show .nav-link,  .nav-link.active{
            border: 1px solid transparent;
            background: #FFF;
            // box-shadow: 2px 2px 0px 0px #FFF;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; 
            text-transform: uppercase;
        }
    }
    
}

@media(max-width:992px) {
    .dopphases .dopphasesinner {
        flex-direction: column;
    }

    .dopphases .dopphasesinner .dopphasesrightimg .dopphasesrightinnerimg {
        width: 100%;
    }

    .dopphases .dopphasesinner {
        padding-top: 70px;
    }

    .dopphases .dopphasesinner .dopphasesleft .dopphaseslefthead {
        font-size: 30px;
        margin-bottom: 30px;
    }

    .dopphases .dopphasesinner .dopphasesleft .dopphasesleftpara {
        font-size: 14px;
    }

    .dopphases.activee {
        padding-bottom: 60px;
    }
    .dopphases .main-heading h4{
        font-size: 32px;
    }
    .dopphases .dopphasesinner .dopphasesleft .dopphaseslefthead{
        text-align: center;
    }
    .dopphases{
        padding-bottom: 44px;
    }
    .desktopphaseimg{
        display: none !important;
    }
    .mobilephaseimg{
        display: block !important;
    }
    .dopphases .dopphasesinner{
        flex-direction: column-reverse;
        gap: 50px;
    }

}

.carousel-control-next,
.carousel-control-prev {
    display: none;
}

.carousel-indicators {
    bottom: 25px;
}