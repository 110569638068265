.communityactivity {
    border: 1px solid #1F1F1F;
    background: #0B0B0B;
    padding-bottom: 114px;
    margin-bottom: 15px;

    .communityactivitycardsheader {
        height: 69px;
        border-bottom: 1px solid #1F1F1F;
        background: #0B0B0B;
        display: flex;
        align-items: center;
        padding: 0px 30px;

        .communityactivitycardsheaderpara {
            color: #6C6C6C;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.014px;
            text-transform: uppercase;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 7px;
        }
    }

    .communityactivityinner {
        .communityactivityhead {
            color: #FFF;
            text-align: center;
            font-size: 45px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            padding-top: 70px;
            padding-bottom: 50px;
        }

        .communitymaincards {
            // display: flex;
            // align-items: flex-start;
            // justify-content: flex-start;
            gap: 20px;
            overflow: hidden;
            display: flex;
            white-space: nowrap; 
            overflow: hidden;

            &::-webkit-scrollbar {
                display: none;
            }

            .communitycard {
                .imgone {
                    min-width: 311px;
                    height: 232px;
                }

                .imgtwo {
                    min-width: 311px;
                    height: 311px;
                }

                .imgthree {
                    min-width: 312px;
                    height: 249px;
                }

                .imgfour {
                    min-width: 311px;
                    height: 232px;
                }

                .imgfive {
                    min-width: 386.868px;
                    height: 308px;
                }

                .communitycardimg {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    object-fit: cover;
                    object-position: center;
                    height: 309px;

                    .communitycardimg {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                .communitycardhead {
                    color: #FFF;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    text-transform: uppercase;
                    margin-top: 16px;
                }
            }
        }
    }
}

@media (max-width:992px){
    .communityactivity .communityactivityinner .communityactivityhead{
        font-size: 20px;
        padding: 40px 0px;
        max-width: 387px;
    }
    .communityactivity{
        padding-bottom: 75px;
    }
    .communityactivity .communityactivityinner .communitymaincards .communitycard .communitycardhead{
        font-size: 16px;
    }
    .communityactivity .communityactivityinner .communitymaincards{
        padding-left: 15px;
    }
}




 .img-border-style{
    border: 2px solid #FFF;
    background: #0B0B0B;
    box-shadow: 3px 3px 0px 0px #FFF;
    overflow: hidden;
 }