.mainviedo{
    background: #0B0B0B;
    padding-bottom: 147px;
    margin-bottom: 15px;
    border: 1px solid #1B1B1B;
    .dopphasesheader {
        height: 69px;
        border-bottom: 1px solid #1F1F1F;
        background: #0B0B0B;
        display: flex;
        align-items: center;
        padding: 0px 30px;
        display: flex;
align-items: center;
justify-content: space-between;

        .dopphasesheaderpara {
            color: #7E7E7E;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.014px;
            text-transform: uppercase;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 7px;
        }
    }
    .blogsinner{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        margin: 0 auto;
        padding-top: 70px;
        gap: 20px;
        flex-direction: column;
        .mainhead{
            h2{
                color: #FFF;
                font-size: 45px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-transform: uppercase;
           
                margin-bottom: 23px;
            }
        }
    }
    .mainparentcards{
     
       
        .item{
            border: 1px solid #000;
            background: #FFF;
            box-shadow: 5px 5px 0px 0px #000;
            // padding: 12px 24px 12px 12px;
            width: 100%;
            .mainimagessss{
                max-width: 406px;
                max-height: 218px;
                object-fit: cover;
                object-position: center;
                overflow: hidden;
                .innerimage{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
    }
}