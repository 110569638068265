.communitycards {
    background: #FFF;
    padding-bottom: 95px;
    margin-bottom: 15px;

    .communitycardsheader {
        height: 69px;
        border-bottom: 1px solid #DFDFDF;
        background: #FFF;
        display: flex;
        align-items: center;
        padding: 0px 30px;

        .communitycardsheaderpara {
            color: #7E7E7E;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.014px;
            text-transform: uppercase;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 7px;
        }
    }

    .communitycardstop {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-top: 80px;
        gap: 20px;
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;

        .communitycardstopleft {
            color: #000;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            border-left: 1px solid #000;
            padding-left: 20px;
            max-width: 525px;
            width: 100%;
        }

        .communitycardstopright {
            color: #494949;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%; 
            max-width: 525px;
            width: 100%;
        }
    }
}

@media(max-width:992px){
    .communitycards .communitycardstop{
        flex-direction: column;
        padding-top: 22px;
        gap: 40px;
    }
    .dopcommunitycards .communitymain{
        flex-wrap: wrap;
    }
    .communitycards .communitycardstop .communitycardstopright{
        padding-left: 21px;
        font-size: 14px;
    }
    .communitycards .communitycardstop .communitycardstopleft{
        font-size: 24px;
    }
    .communitycards{
        padding-bottom: 53px;
    }
}