.techcards {
    background: #FFF;
    padding-bottom: 120px;
    margin-bottom: 15px;

    .techcardsheader {
        height: 69px;
        border-bottom: 1px solid #DFDFDF;
        background: #FFF;
        display: flex;
        align-items: center;
        padding: 0px 30px;

        .techcardsheaderpara {
            color: #7E7E7E;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.014px;
            text-transform: uppercase;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 7px;
        }
    }

    .techcardstop {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-top: 66px;
        padding-bottom: 90px;
        gap: 20px;
        max-width: 1296px;

        .techcardstopleft {
            color: #000;
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            border-left: 1px solid #000;
            padding-left: 20px;
            max-width: 873px;
            width: 100%;
        }

        .techcardstopright {
            color: #494949;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            text-transform: capitalize;
            max-width: 573px;
            width: 100%;
        }
    }

    .techcardsmain {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        max-width: 1200px;
        margin: 0 auto;
        width: 100%;
        // padding-bottom: 110px;

        .techcard {
            border: 1px solid #000;
            background: #FFF;
            box-shadow: 5px 5px 0px 0px #000;
            display: flex;
            padding: 30px;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            min-height: 324px;

            .techcardhead {
                color: #000;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                padding-top: 40px;
                padding-bottom: 30px;
            }

            .techcardpara {
                color: #494949;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
            }
        }
    }

    .techvideo {
        .techvideohead {
            color: #000;
            text-align: center;
            font-size: 45px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            margin-bottom: 50px;
        }

        .video-player {
            max-width: 1200px;
            margin: 0 auto;
            width: 100%;
            position: relative;
            border: 1px solid #000;
            background: #FFF;
            box-shadow: 5px 5px 0px 0px #000;
            height: 675.5px;

            &:hover {
                .pause-button-main {
                    display: flex !important;
                }
            }

            .play-button-main {
                background: rgba(0, 0, 0, 0.70);
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                display: flex;
                justify-content: center;
                align-items: center;

                .play-button {
                    border: 1px solid #000;
                    background: #FFF;
                    box-shadow: 5px 5px 0px 0px #000;
                    display: flex;
                    padding: 12px 24px 12px 12px;
                    align-items: center;
                    justify-content: center;
                    color: #000;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    text-transform: uppercase;
                }
            }

            .pause-button-main {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                display: none;
                justify-content: center;
                align-items: center;

                .play-button {
                    border: 1px solid #000;
                    background: #FFF;
                    box-shadow: 5px 5px 0px 0px #000;
                    display: flex;
                    padding: 12px 24px 12px 12px;
                    align-items: center;
                    justify-content: center;
                    color: #000;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    text-transform: uppercase;
                }
            }

            .video-player-video {
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

@media (max-width:992px) {
    .techcards .techcardstop {
        flex-direction: column;
        gap: 70px;
        padding-top: 35px;
    }

    .techcards .techcardstop .techcardstopleft {
        font-size: 20px;
    }

    .techcards .techcardstop .techcardstopright {
        font-size: 16px;
        padding-left: 21px;
    }

    .techcards .techcardsmain {
        grid-template-columns: 1fr;
    }

    .techcards .techcardsmain .techcard {
        min-height: 293px;
    }

    .techcards .techcardsmain .techcard .techcardhead {
        font-size: 20px;
    }

    .techcards .techcardsmain .techcard .techcardimg {
        width: 45px;
        height: 45px;
    }
    .techcards .techcardsmain{
        padding-bottom: 60px;
    }
    .techcards .techvideo .techvideohead{
        font-size: 20px;
        margin-bottom: 30px;
    }
    .techcards .techvideo .video-player{
        height: 218.412px;
    }
    .techcards{
        padding-bottom: 55px;
    }
    .techcards .techvideo .video-player .play-button-main .play-button{
        font-size: 8.407px;
        padding: 3.88px 7.76px 3.88px 3.88px;
        svg{
            width: 19.4px;
height: 19.4px;
        }
    }
    .techcards .techvideo .video-player .pause-button-main .play-button{
        font-size: 8.407px;
        padding: 3.88px 7.76px 3.88px 3.88px;
        svg{
            width: 19.4px;
height: 19.4px;
        }
    }
}