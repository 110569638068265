.usecasesmain {
    border: 1px solid #1B1B1B;
    background: #000;
    margin-bottom: 15px;
    padding-bottom: 77px;

    .usecasesheader {
        height: 69px;
        border-bottom: 1px solid #1B1B1B;
        background: #000;
        display: flex;
        align-items: center;
        padding: 0px 30px;

        .usecasesheaderpara {
            color: #6C6C6C;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.014px;
            text-transform: uppercase;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 7px;
        }
    }

    .usecaseshead {
        color: #FFF;
        text-align: center;
        font-size: 45px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
        margin-top: 53px;
        margin-bottom: 45px;
    }

    .usecasesinner {
        display: flex;
        max-width: 1100px;
        width: 100%;
        margin: 0 auto;
        border: 1px solid #FFF;

        .nav-pills .nav-link.active,
        .nav-pills .show>.nav-link {
            background: #1F1F1F !important;
        }

        .nav {
            flex-direction: column;
            max-width: 398px;
            width: 100%;

            .nav-item {
                .nav-link {
                    display: flex;
                    padding: 23px 45px;
                    align-items: center;
                    gap: 12px;
                    border-bottom: 1px solid #FFF;
                    background: #000;
                    color: #FFF;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    text-transform: uppercase;
                    border-radius: 0px;

                    .bold {
                        font-weight: 700;
                    }
                }
            }
        }

        .usecasesright {
            background: #1F1F1F;
            width: 100%;
            border-left: 1px solid #FFF;
            color: #FFF;
            padding: 30px;

            .usecasespara {
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                margin-bottom: 24px;

                .bold {
                    font-weight: 700;
                }
            }

            .usecaseslist {
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;
                margin-bottom: 14px;

                .square {
                    min-width: 12.877px;
                    min-height: 12.877px;
                    background: #FFF;
                    margin-top: 3px;
                }
            }
        }
    }
}

@media(max-width:992px) {
    .usecasesmain .usecasesinner {
        display: none !important;
    }

    .usecasesmain .usecaseshead {
        font-size: 24px;
        margin-top: 40px;
        margin-bottom: 25px;
    }

    .usecasesmain .usecasesinnermbl {
        display: block !important;
        padding: 0px 20px;

        .accordion {
            background: transparent;
            border: none;
            box-shadow: none;
            outline: none;
            padding: 0px;
            margin: 0px;
            border: 1px solid #FFF;
            border-radius: 0px;

            .accordion-item {
                background: transparent;
                border: none;
                box-shadow: none;
                outline: none;
                padding: 0px;
                margin: 0px;
                border-radius: 0px;

                .accordion-header {
                    background: transparent;
                    border: none;
                    box-shadow: none;
                    outline: none;
                    padding: 0px;
                    margin: 0px;
                    border-radius: 0px;
                    

                    .accordion-button::after {
                        background: url('../../../assets/accadd.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        padding: 0px;
                        width: 28px;
                        height: 28px;
                    }

                    .accordion-button:not(.collapsed)::after{
                        background: url('../../../assets/accminus.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        padding: 0px;
                        width: 28px;
                        height: 28px;
                    }

                    .accordion-button {
                        background: transparent;
                        border-bottom: 1px solid #FFF;
                        box-shadow: none;
                        outline: none;
                        padding: 23px 20px;
                        margin: 0px;
                        color: #FFF;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                        text-transform: uppercase;
                        border-radius: 0px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 12px;

                        .bold {
                            font-weight: 700;
                        }
                    }
                }

                .accordion-body {
                    background: transparent;
                    border-bottom: 1px solid #FFF;
                    box-shadow: none;
                    outline: none;
                    padding: 30px;
                    margin: 0px;
                    border-radius: 0px;

                    .usecasespara {
                        color: #FFF;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                        margin-bottom: 24px;

                        .bold {
                            font-weight: 700;
                        }
                    }

                    .usecaseslist {
                        color: #FFF;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 10px;
                        margin-bottom: 14px;

                        .square {
                            min-width: 12.877px;
                            min-height: 12.877px;
                            background: #FFF;
                            margin-top: 5px;
                        }
                    }
                }
            }
        }
    }
}