.main-navbar {
    border: 1px solid #3A3A3A;
    background: rgba(0, 0, 0, 0.70);
    height: 69px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 999;

    .navbar-brand {
        margin: 0;
    }

    .navbar-nav {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 781px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;

        .drop-arrow {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            position: relative;

            a {
                color: #9A9A9A;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                letter-spacing: -0.012px;
                text-transform: uppercase;
                padding: 0;
            }

            .dropdown {
                position: static !important;

                .dropdown-menu {
                    right: 0 !important;
                }
            }

            svg {
                margin-top: -2px;
                cursor: pointer;
            }
        }

        .nav-item {
            .nav-link {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;
                color: #9A9A9A;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                letter-spacing: -0.012px;
                text-transform: uppercase;
                padding: 0;

                &::after {
                    display: none;
                }
            }

            .dropdown-menu {
                .parent-menu {
                    border: 1px solid #FFF;
                    box-shadow: 2px 2px 0px 0px #FFF;
                    padding: 0;
                    margin-top: 15px;
                    overflow: hidden;

                    a {
                        border-bottom: 1px solid #1F1F1F;
                        background: #000;
                        padding: 14px 15px;
                        color: #9D9D9D;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: uppercase;
                        transition: 0.5s linear;

                        &:hover {
                            color: #FFF;
                            border-bottom: 1px solid #1F1F1F;
                            background: #0A0A0A;
                        }
                    }
                }
            }
        }
    }

    .dropdown-menu {
        display: block;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.8s ease;
        visibility: hidden;
        background-color: transparent;
    }

    .dropdown:hover .dropdown-menu {
        max-height: 300px;
        visibility: visible;
        pointer-events: visible;
    }
    .twice-btns{
        display: flex;
        align-items: center;
        gap: 24px;
        
    }

}


.mobile-menu-offcanvas {
    background: #000;
    min-width: 100%;

    .offcanvas-header {
        border-bottom: 1px solid #1E1D1D;
        background: #000;
        padding: 22px 20px;

        .btn-close {
            background: url("../../../assets/close-icon.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            padding: 0;
            opacity: 1;
            box-shadow: none;
        }
    }

    .offcanvas-body {
        padding: 0;

        .navbar-nav {


            .dropdown {
                a {
                    border-top: 1px solid #1E1D1D;
                    background: #000;
                    padding: 22px 20px;
                    color: #9A9A9A;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    letter-spacing: -0.014px;
                    text-transform: uppercase;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &[aria-expanded="true"] {
                        color: #fff;

                        svg {
                            transform: rotate(180deg);
                        }
                    }

                    &::after {
                        display: none;
                    }
                }

                .dropdown-menu {
                    padding: 0;
                    position: static;
                    transform: unset;
                    width: 100%;

                    &.show {
                        padding: 0;
                        position: static !important;
                        transform: unset !important;
                        width: 100%;
                    }

                    a {
                        color: #9A9A9A;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                        letter-spacing: -0.014px;
                        text-transform: uppercase;
                        padding: 18px 35px;
                        background: #090909;
                        border-bottom: 1px solid #141414;
                    }
                }
            }

            .drop-arrow {
                border-top: 1px solid #1E1D1D;
                background: #000;
                padding: 22px 20px;
                color: #9A9A9A;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                letter-spacing: -0.014px;
                text-transform: uppercase;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;

                a {
                    color: #9A9A9A;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    letter-spacing: -0.014px;
                    text-transform: uppercase;
                }

                .dropdown {
                    position: static !important;
                }
                .dropdown-toggle{
                    &.active{
                            transform: rotate(180deg);
                        
                    }
                }
            }

            .mobile-menu {
                a {
                    border-bottom: 1px solid #141414;
                    background: #090909;
                    padding: 18px 35px;
                    color: #9A9A9A;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    letter-spacing: -0.014px;
                    text-transform: uppercase;
                    
                }
            }
        }
    }
}


@media (max-width:600px){
    .twice-btnssssss{
        display: flex;
        align-items: center;
        gap: 5px;
        .btn-launch{
            display: flex !important;
        }
    }
}

@media (max-width:350px){
    .twice-btnssssss{
        display: flex;
        align-items: center;
        gap: 0px;
        .btn-launch{
            display: flex !important;
        }
    }
}

@media (max-width:330px){
    .twice-btnssssss{
        display: flex;
        align-items: center;
        gap: 0px;
        .btn-launch{
            display: flex !important;
          
        }
    }
    .main-navbar .navbar-brand img{
        width: 50px;
    }
    .btn-launch h6{
        padding: 8px;
        font-size: 10px;
    }
}

.market-menu{
     a{
        justify-content: flex-start !important;
     }
}

.set-font-normal{
    a{
        text-transform: none !important;
        span{
            color: #999;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}