.partners-section {
    border: 1px solid #1B1B1B;
    background: #000;
    margin-top: 15px;
    padding-bottom: 91px;

    .top-left-text {
        border-bottom: 1px solid #1B1B1B;
        background: #000;
        padding-left: 30px;
        height: 69px;
        display: flex;
        align-items: center;
        margin-bottom: 70px;

        p {
            color: #6C6C6C;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.014px;
            text-transform: uppercase;
        }
    }

    .parent {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 161px;

        .left-side {
            max-width: 469px;
            width: 100%;

            .main-content {
                h4 {
                    color: #FFF;
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    text-transform: uppercase;
                    margin-bottom: 36px;
                }

                p {
                    color: #9A9A9A;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                }
            }
        }

        .right-side {
            flex: 1;

            .parent-box {
                display: grid;
                grid-template-columns: repeat(2, 1fr);

                .single-item {
                    padding: 30px;
                    border: 1px solid #2E2E2E;
                    background: #000;

                    p {
                        color: #959595;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                        text-transform: capitalize;
                        margin-bottom: 40px;

                    }

                    h6 {
                        color: #FFF;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                    }

                }
            }
        }
    }

    .bottom-slider {
        margin-top: 60px;

        .single-item {
            padding: 30px;
            border: 1px solid #2E2E2E;
            background: #000;

            p {
                color: #959595;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                text-transform: capitalize;
                margin-bottom: 40px;

            }

            h6 {
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
            }

        }
    }

    .owl-carousel .owl-item img {
        width: auto;
        height: auto;
    }

    .bottom-slider1{
        .owl-carousel .owl-stage {
            display: flex;
            animation: slide linear infinite;
            animation-duration: 120s;
        }
    
        @keyframes slide {
            0% {
                transform: translateX(0);
            }
    
            100% {
                transform: translateX(-100%);
            }
        }
    
        .owl-carousel .owl-item {
            flex-shrink: 0;
            width: 100px;
            /* Adjust the width based on the number of items */
        }
    
        .owl-carousel .owl-stage:hover {
            animation-play-state: paused;
            /* Pause animation on hover */
        }
    }

    .bottom-slider-mobileblock{
        margin-top: 10px !important;
        .owl-carousel .owl-stage {
            display: flex;
            animation: slideReverse linear infinite;
            animation-duration: 120s; /* Adjust the duration for speed */
          }
          
          @keyframes slideReverse {
            0% {
              transform: translateX(-100%); /* Start from the left-most point */
            }
            100% {
              transform: translateX(0); /* Move towards the right */
            }
          }
          
          .owl-carousel .owl-item {
            flex-shrink: 0;
            width: 100px; /* Adjust the width based on the number of items */
          }
          
          .owl-carousel .owl-stage:hover {
            animation-play-state: paused; /* Pause animation on hover */
          }
    }
}


@media (max-width:1024px) {
    .partners-section .parent {
        flex-direction: column;
        gap: 60px;
    }

    .partners-section .parent .left-side .main-content h4 {
        font-size: 35px;
    }

    .partners-section .parent .right-side .parent-box .single-item {
        height: 100%;
    }
}

@media (max-width:600px) {
    .partners-section .parent .right-side .parent-box .single-item p {
        max-width: 50px;
    }

    .partners-section .bottom-slider-mobileblock {
        display: block !important;
    }
}