.transparency-section {
    background: #FFF;
    padding-bottom: 90px;
    overflow: hidden;

    .top-left-text {
        border-bottom: 1px solid #DFDFDF;
        background: #FFF;
        padding-left: 30px;
        height: 69px;
        display: flex;
        align-items: center;
        margin-bottom: 70px;

        p {
            color: #7E7E7E;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.014px;
            text-transform: uppercase;
        }
    }

    .main-heading {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 56px;

        h5 {
            color: #000;
            text-align: center;
            font-size: 45px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            margin-bottom: 30px;
        }

        p {
            color: #494949;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            text-transform: capitalize;
            max-width: 688px;
            width: 100%;
            margin: 0 auto;
            margin-bottom: 15px;
        }

        h6 {
            color: #494949;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-align: center;
        }
    }

    .bottom-cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        .single-card {
            border: 1px solid #000;
            background: #FFF;
            box-shadow: 5px 5px 0px 0px #000;
            padding: 30px;
            overflow: hidden;
            h6{
                color: #000;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%; 
                margin-top: 40px;
                margin-bottom: 30px;
            }
            p{
                color: #494949;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
            }
        }
    }
}




@media (max-width: 1024px){
    .transparency-section .bottom-cards{
        grid-template-columns: repeat(1, 1fr);
    }
    .transparency-section .bottom-cards .single-card h6{
        font-size: 20px;
    }
}




@media (max-width:390px){
    .transparency-section .main-heading h5{
        font-size: 38px;
    }
}