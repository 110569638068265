.termofuse-section{
    padding-top: 134px;
    padding-bottom: 101px;
    .parent{
        max-width: 806px;
        width: 100%;
        margin: 0 auto;
 
        h4{
            color: #FFF;
            font-size: 40px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; 
        }
        .last-update{
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%; 
            margin-top: 10px;
            margin-bottom: 40px;
        }
        h6{
            color: #FFF;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%; 
        }
        .para{
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; 
            margin: 15px 0;
            span{
                font-weight: 700;
                text-decoration: underline !important;
            }
            .bold{
                font-weight: 700;
                text-decoration: unset !important;
            }
            a{
                color: #FFF;
                font-weight: 700;
                text-decoration: underline !important;
            }
        }
        ul{
            .textstylenone{
                list-style-type: none !important;
                li{
                    list-style-type: none !important;
                }
            }
            li{
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
                list-style-type: disc;
                margin: 15px 0;
                margin-left: 30px;
                span{
                    text-decoration: underline !important;
                }
            }
        }
        ol{
            list-style-type: lower-alpha;
            li{
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
                margin: 15px 0;
                margin-left: 30px;
                span{
                    text-decoration: underline !important;
                }
            }
        }
    }
}

@media (max-width:600px){
    .termofuse-section{
        padding: 50px 0;
    }
}