.mainteam {
    background: url(../../../src/assestsss/bg-team.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 464px;
    position: relative;
    margin-bottom: 20px;

    .mainhead {
        color: #FFF;
        font-size: 84px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        /* 100.8px */
        text-transform: uppercase;
        position: absolute;
        bottom: 50px;
        left: 42px;
        // padding-top: 258px;
    }
}

.theteam {
    background: #FFF;
    position: relative;
    z-index: 99;
    padding-bottom: 161px;

    .top-left-text {
        border-bottom: 1px solid #DFDFDF;

        background: #FFF;

        padding-left: 30px;
        height: 69px;
        display: flex;
        align-items: center;
        margin-bottom: 68px;

        p {
            color: #7E7E7E;
            ;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            /* 16.8px */
            letter-spacing: -0.014px;
            text-transform: uppercase;
        }
    }

    .parenttext {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 70px;
        margin-bottom: 90px;

        .left {
            p {
                color: #000;
                font-size: 25px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                border-left: 1px solid #000;
                padding-left: 20px;
            }
        }

        .right {
            p {
                color: #494949;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
                border-left: 1px solid transparent;
                padding-left: 20px;
            }
        }
    }
}

.joinus {
    background: url(../../../src/assestsss/bg-teamsss.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 387px;
    padding-top: 64px;

    .mainheadingsss {
        max-width: 649.498px;
        width: 100%;

        .joinhead {
            color: #FFF;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            /* 48px */
            text-transform: uppercase;

            margin-bottom: 49px;
        }

        .btn-launch {
            width: 141px;
        }

        h6 {
            white-space: nowrap;
        }

        svg {
            width: 41px;
        }
    }

}

@media(max-width:992px) {
    .theteam .parenttext {
        flex-direction: column;
        gap: 40px;
    }

    .joinus .mainheadingsss .joinhead {
        font-size: 30px;
    }

    .theteam .parenttext .right p {
        font-size: 16px;
    }

    .theteam .parenttext .left p {
        font-size: 20px;
    }

    .mainteam .mainhead {
        font-size: 49px;
    }

    .mainteam .mainhead {
        padding-top: 351px;
    }
}