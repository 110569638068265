.mainacademy{
    background: #0B0B0B;
    padding-bottom: 147px;
    margin-bottom: 15px;
    .dopphasesheader {
        height: 69px;
        border-bottom: 1px solid #1F1F1F;
        background: #0B0B0B;
        display: flex;
        align-items: center;
        padding: 0px 30px;

        .dopphasesheaderpara {
            color: #7E7E7E;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.014px;
            text-transform: uppercase;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 7px;
        }
    }
    .parentheadssss{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 70px;
        h2{
            color: #FFF;
            font-size: 45px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; 
            text-transform: uppercase;
            margin-bottom: 20px;
        }
        p{
            color: #C6C6C6;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.018px;
            text-transform: uppercase;
            margin-bottom: 50px;

        }
    }
    .mmmmm{
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }
    .academycardparent{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 24px;
  
        .academycards{
            border: 1px solid #2D2D2D;
            background: transparent;
            width: 315px;
            height: 315px;
            margin-bottom: 63px;
            a{
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%; 
                letter-spacing: -0.018px;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
               
         
            }
            .set-margin-top{
                margin-top: 20px;
            }
            .cardssimage{
                max-width: 315px;
                max-height: 315px;
                object-fit: cover;
                object-position: center;
                overflow: hidden;
                .innerimagess{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
    }
}
@media(max-width:600px){
    .mainacademy .academycardparent{
        flex-wrap: wrap;
        gap: 24px 0;
    }
    .mainacademy .academycardparent .academycards{
        width: 170px;
        height: 170px;
    }
    .mainacademy .academycardparent{
        justify-content: space-between;
    }
    .mainacademy .mmmmm{
        justify-content: space-between !important;
    }
    .mainacademy{
        padding-bottom: 77px !important;
    }
    .mainexplainer{
        padding-top: 0 !important;
    }
    .mainexplainer{
        padding-bottom: 40px !important;
    }
}

@media (max-width: 412px){
    .mainacademy .academycardparent .academycards{
        width: 160px;
        height: 160px;
    }
}

@media (max-width:390px){
    .mainacademy .academycardparent .academycards{
        width: 150px;
        height: 150px;
    }
    .mainacademy .academycardparent .academycards a{
        font-size: 16px;
    }
    .mainviedo .dopphasesheader{
        padding: 0 15px !important;
    }
}