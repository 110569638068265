.blog-section{
    min-height: 100vh;
    padding-top: 40px;
    background-color: #000;
    padding-bottom: 120px;
    .main-heading{
        margin-bottom: 74px;
        h6{
            color: #FFF;
            text-align: center;
            font-size: 58px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; 
            text-transform: uppercase;
            text-align: start;
        }
    }
    .featured-new-content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        border: 2px solid #FFF;
        background: #FFF;
        .common-width{
            flex: 0 0 50%;
        }
        .right-content{
            padding-left: 20px;
            padding-right: 45px;
            h6{
                background: #000;
                padding: 8px 13px;
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%; 
                text-transform: uppercase;
                display: inline-block;
            }
            h4{
                margin: 15px 0;
                color: #000;
                font-size: 28px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%; 
            }
            p{
                color: #9A9A9A;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%; 
                text-transform: uppercase;
            }
        }
    }
    .bottom-blogs{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        .single-card{
            border: 2px solid #FFF;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: #FFF;
            .main-img{
                width: 100%;
                height: 190px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .bottom-text{
                border: 1px solid #FFF;
                background: #FFF;
                padding: 14px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-height: 100px;
                .left{
                    max-width: 263px;
                    width: 100%;
                    .set-ellipses{
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        -webkit-line-clamp: 2; 
                        text-overflow: ellipsis;
                    }
                    h6{
                        color: #000;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%; 
                        text-transform: uppercase;
                    }
                    p{
                        color: #000;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                       
                    }
                }
                .right{
                    .btn-readmore{
                        color: #FFF;
                        font-size: 13.057px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%; 
                        letter-spacing: -0.013px;
                        border: 0.933px solid #000;
                        background: #FFF;
                        box-shadow: 1.865px 1.865px 0px 0px #000;
                        width: 32px;
                        height: 32px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    }
}

.blog-detail{
    min-height: 100vh;
    padding-top: 40px;
    background-color: #000;
    padding-bottom: 120px;
    .upper-bar{
        margin-bottom: 40px;
        position: sticky;
        top: -2px;
        background-color: #000;
        padding: 5px 0;
        z-index: 9;
        padding-top: 30px;
        .parent-bar{
            width: 100%;
            height: 9px;
            background: rgba(255, 255, 255, 0.50);
        }
    }
    .parent-content{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .left-side{
            width: 447px;
            position: sticky;
            top: 115px;
            left: 0;
            .back-btn{
                margin-bottom: 30px;
                button{
                    background-color: transparent;
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 10px;
                    color: #FFF;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%; 
                }
            }
            .heading-main{
                h4{
                    color: #FFF;
                    font-size: 50px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    text-transform: uppercase;
                }
                .inner-twice-text{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 15px;
                    margin-bottom: 30px;
                    padding-right: 50px;
                    p{
                        color: #FFF;
                        font-size: 20.241px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%; 
                    }
                    a{
                        color: #FFF;
                        font-size: 20.241px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%; 
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 6px;
                    }
                }
                .bottom-social{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 15px;
                }
            }
        }
        .right-side-content{
            width: 728px;
            .main-img-blogdetail{
                width: 100%;
                overflow: hidden;
                height: 253px;
                margin-bottom: 20px;
                border: 3px solid #fff;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    padding: 0;
                }
            }
            .single-head{
                h6{
                    color: #FFF;
                    font-style: normal;
                    line-height: 120%;
                }
                p{
                    color: #FFF;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    margin: 5px 0;
                }
            }
        }
    }
    .right-side-content{
        img{
            max-width: 100%;
            object-fit: contain;
            height: auto;
            padding: 40px 0;
            margin: 0 auto;
            display: block;
        }
        .single-head{
            h1,h2,h3,h4,h5,h6{
                color: #FFF;
                margin: 25px 0;
                font-weight: 600;
            }
            a{
                font-weight: 700;
                text-decoration: underline !important;
                color: #FFF;
            }
            iframe{
                width: 100% !important;
                height: 500px !important;
                padding: 40px 0;
                object-fit: contain;
            }
            ul li{
                color: #fff;
                margin: 15px 0;
            }
            ol li{
                color: #fff;
                margin: 15px 0;
            }
            div{
                color: #FFF;
            }
        }
    }
}

@media (max-width: 991px){
    .blog-section .bottom-blogs{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px){
    .blog-section .bottom-blogs{
        grid-template-columns: repeat(1, 1fr);
    }
    .blog-detail .parent-content{
        flex-direction: column;
        gap: 50px;
    }
    .blog-detail .parent-content .left-side{
        width: 100%;
    }
    .blog-detail .parent-content .right-side-content{
        width: 100%;
    }
    .blog-detail .parent-content .left-side .heading-main h4{
        font-size: 32px;
    }
    .blog-detail .parent-content .right-side-content .single-head h6{
        font-size: 18px;
    }
    .blog-detail .parent-content .left-side{
        position: static;
    }
    .blog-detail .parent-content .right-side-content .single-head p{
        font-size: 16px;
    }
    .blog-section .bottom-blogs .single-card .bottom-text{
        align-items: center;
    }
    .blog-detail .parent-content .left-side .heading-main .inner-twice-text p{
        font-size: 16px;
    }
    .blog-detail .parent-content .left-side .heading-main .inner-twice-text a{
        font-size: 16px;
    }
    .blog-detail .parent-content .right-side-content .main-img-blogdetail{
        height: 150px;
    }
}

.blog-section .bottom-blogs .single-card .bottom-text .left p{
    margin-top: 10px;
}


.set-loading-mid{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 25px;
    
}

@media (max-width:600px){
    .blog-section .featured-new-content{
        flex-direction: column;
    }
    .blog-section .featured-new-content .right-content{
        padding: 25px;
    }
    .blog-section .featured-new-content .right-content h4{
        font-size: 20px;
    }
}