.dop-social-section {
    margin: 15px 0;

    .parent {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left-side {
            max-width: 483px;
            width: 100%;

            .main-heading {
                // border-top: 1px solid #1B1B1B;
                // border-bottom: 1px solid #1B1B1B;
                // border-left: 1px solid #1B1B1B;
                border-right: 1px solid #1B1B1B;
                background: #000;
                padding: 45px 50px;
                display: flex;
                justify-content: center;
                align-items: center;

                h6 {
                    color: #FFF;
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    text-transform: uppercase;
                }
            }

        }

        .right-side {
            flex: 1;
            border-top: 1px solid #1B1B1B;
            border-bottom: 1px solid #1B1B1B;

            .social-icons {
                display: flex;
                justify-content: space-between;
                align-items: center;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: 0.1s linear;

                a {
                    border-top: 1px solid #1B1B1B;
                    border-right: 1px solid #1B1B1B;
                    border-bottom: 1px solid #1B1B1B;
                    border-left: 1px solid transparent;
                    background: #000;
                    padding: 45px 50px;
                    max-width: 184px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:hover {
                        background: #070707;

                    }

                }

            }
        }
    }
}

@media (max-width:600px) {
    .dop-social-section .parent {
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .dop-social-section .parent .right-side .social-icons a {
        padding: 0;
        max-width: 139px;
        height: 132px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #1B1B1B;
        border-right: 1px solid #1B1B1B;
        border-left: 1px solid #1B1B1B;
        background: #000;

    }

    .dop-social-section .parent .right-side {
        flex: auto;
        width: 100%;
    }

    .dop-social-section .parent .right-side .social-icons {
        flex-wrap: wrap;
    }

    .dop-social-section .parent .left-side .main-heading h6 {
        font-size: 32px;
    }

    .dop-social-section .parent .left-side .main-heading {
        border-top: 1px solid #1B1B1B;
        border-right: 1px solid #1B1B1B;
        border-left: 1px solid #1B1B1B;
        background: #000;
        padding: 45px 50px;

    }

    .dop-social-section .parent .right-side .social-icons a {
        max-width: 100%;
        flex: 1 0 32%;
        height: 124px;
    }
}