.comingsoon-page{
    .parent{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 50px;
        p{
            color: #FFF;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            margin-top: 30px;
            margin-bottom: 10px;
        }
        h6{
            color: #FFF;
            text-align: center;
            font-size: 50px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; 
            text-transform: uppercase;
        }
    }
}


@media (max-width:600px){
    .comingsoon-page .parent p{
        font-size: 16px;
    }
    .comingsoon-page .parent h6{
        font-size: 30px;
    }
    .comingsoon-page{
        padding-bottom: 70px !important;
    }
}