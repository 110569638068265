.planmain {
    min-height: 100vh;

    .desktop-boxes-center {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        img {
            max-width: 397px;
            opacity: 1 !important;
        }

        .opac0 {
            opacity: 0;
        }

        .boxmain1 {
            margin-left: -120px;
        }

        .boxmain2 {
            margin-top: -100px;
        }
    }

    .planinner {
        background: #0B0B0B;
        padding-bottom: 7px;
        margin-bottom: 15px;

        .mainplaninner {
            position: relative;
            width: 100%;
            height: 100%;
            padding-top: 180px;
            max-width: 1180px;
            margin: 0 auto;
            width: 100%;

            .innerplanimg {
                width: 397px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .mainplantexts {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .innerplantexts {
                    padding-bottom: 136px;
                    width: 348px;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-direction: column;

                    .plantexthead {
                        color: #FFF;
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 140%;
                        text-transform: uppercase;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 8px;
                        margin-bottom: 8px;

                        svg {
                            flex-shrink: 0;
                            width: 24px;
                            height: 24px;
                        }
                    }

                    .plantextpara {
                        color: #E1E1E1;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                    }
                }
            }
        }

        .planinnerheader {
            height: 69px;
            border-bottom: 1px solid #1F1F1F;
            background: #0B0B0B;
            display: flex;
            align-items: center;
            padding: 0px 30px;

            .planinnerheaderpara {
                color: #7E7E7E;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                letter-spacing: -0.014px;
                text-transform: uppercase;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 7px;
            }
        }
    }
}

@media(max-width:1170px) {
    .planmain .planinner .mainplaninner .innerplanimg {
        display: none;
    }

    .planmain .planinner .mainplaninner .mainplantexts .innerplantexts {
        width: unset;
        padding-bottom: 65px;
    }

    .planmain .planinner .mainplaninner .mainplantexts {
        justify-content: flex-start;
        padding-left: 205px;
    }

    .planmain .planinner .mainplaninner .innerplanimgmbl {
        display: block !important;
        position: absolute;
        left: 0px;
        top: 46px;
    }

    .planmain .planinner .mainplaninner {
        padding-top: 86px;
    }

    .planmain .planinner .mainplaninner .mainplantexts .innerplantexts .plantextpara {
        width: 206px;
        font-size: 9.487px;
    }

    .planmain .planinner .mainplaninner .mainplantexts .innerplantexts .plantexthead {
        font-size: 13.044px;
        margin-bottom: 4px;

        svg {
            width: 18px;
            height: 18px;
        }
    }

    .planmain .planinner {
        padding-bottom: 55px;
    }
}


@media (max-width:600px) {
    .displaynoneinmobile {
        display: none !important;
    }

    .displayblockinmobile {
        display: block !important;
    }
}

@media(max-width:470px) {
    .planmain .planinner .mainplaninner .innerplanimgmbl {
        width: 150px;
    }

    .planmain .planinner .mainplaninner .mainplantexts {
        padding-left: 160px;
    }

    .planmain .planinner .mainplaninner .mainplantexts .innerplantexts .plantextpara {
        font-size: 8.487px;
    }

    .planmain .planinner .mainplaninner .mainplantexts .innerplantexts {
        padding-bottom: 43px;
    }
}


.mobile-plan {
    position: relative;
    padding-top: 36px;

    &::before {
        content: '';
        position: absolute;
        top: 110px;
        left: 42px;
        height: 85%;
        width: 1px;
        background: #fff;
    }

    .parent-box {
        display: flex;
        position: relative;
        z-index: 1;
        margin-bottom: 40px;

        .left-box {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            max-width: 85px;
            width: 100%;
        }

        .right-box {
            flex-grow: 1;
            margin-left: 25px;

            h6 {
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
                text-transform: uppercase;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 8px;

                svg {
                    flex-shrink: 0;
                    width: 24px;
                    height: 24px;
                }
            }

            p {
                color: #B1B1B1;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                margin-top: 5px;
            }
        }
    }
}



// new plan scss here ..................

.plannew-section {
    padding: 70px 0;

    .accordion {
        .accordion-item {
            border-radius: 0;
            background-color: transparent;
            border: 1px solid #FFF;
            background: #0B0B0B;
            margin-bottom: 20px;
            box-shadow: none;

            .accordion-button {
                border: 1px solid #FFF;
                background: #0B0B0B;
                padding: 40px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                box-shadow: none;

                &::after {
                    background: url("../../assets/accordion-arrow.svg");
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }

                &[aria-expanded="true"] {
                    background: #202020;
                }

                h6 {
                    color: #FFF;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    letter-spacing: -0.018px;
                    text-transform: uppercase;

                    span {
                        color: #FFF;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%;
                        letter-spacing: -0.018px;
                        text-transform: uppercase;
                    }
                }
            }

            .accordion-body {
                padding: 40px;

                .parent-cards {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: auto;
                    gap: 15px;

                    .single-card {
                        border: 1px solid #1F1F1F;
                        background: #0B0B0B;
                        padding: 30px;

                        h6 {
                            color: #FFF;
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 10px;
                            margin-top: 30px;
                            margin-bottom: 10px;

                            span {
                                color: #FFF;
                                font-size: 22px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 140%;
                            }
                        }

                        p {
                            color: #9A9A9A;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%;
                        }

                        .twice-itemss {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 10px;
                        }

                        .twice-text {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 10px;
                            margin-top: 30px;
                            margin-bottom: 10px;

                            h6 {
                                margin: 0 !important;
                            }

                            img {
                                width: auto;
                                height: auto;
                            }
                        }
                    }

                    .last-card {
                        grid-column: 1 / 3;
                        justify-self: center;
                        align-self: center;
                        max-width: 582px;
                    }
                }

                .bottom-detail {
                    border: 1px solid #1F1F1F;
                    background: #0B0B0B;
                    padding: 20px 30px;
                    margin-top: 25px;

                    p {
                        color: #9A9A9A;
                        text-align: center;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 150%;
                    }
                }
            }
        }
    }
}


.ifheightsmallsetreplan {
    .techbanner {
        height: 200px;
    }

    .techbanner .techbannerinner {
        height: 200px;
    }

    .techbanner .techbannerinner .techhead {
        font-size: 50px;
    }

    .plannew-section .accordion .accordion-item .accordion-button h6 {
        font-size: 16px;
    }

    .plannew-section .accordion .accordion-item .accordion-button h6 span {
        font-size: 16px;
    }

    .plannew-section .accordion .accordion-item .accordion-body .parent-cards .single-card h6 {
        font-size: 20px;
    }

    .plannew-section .accordion .accordion-item .accordion-body .parent-cards .single-card h6 span {
        font-size: 20px;
    }

    .plannew-section .accordion .accordion-item .accordion-body .parent-cards .single-card p {
        font-size: 16px;
    }

    .plannew-section .accordion .accordion-item .accordion-button {
        padding: 20px;
    }

    .plannew-section .accordion {
        max-width: 1260px;
        width: 100%;
        margin: 0 auto;
    }
    .plannew-section{
        padding: 50px 0;
    }
    .plannew-section .accordion .accordion-item .accordion-body{
        padding: 30px;
    }

    .plannew-section .accordion .accordion-item .accordion-body .parent-cards .single-card img{
        width: 40px;
        height: 40px;
    }
    .plannew-section .accordion .accordion-item .accordion-body .parent-cards .single-card h6 img{
        width: auto;
        height: auto;
    }
    .plannew-section .accordion .accordion-item .accordion-body .parent-cards .single-card .twice-text img{
        width: auto;
        height: auto;
    }
    

}
.ifheightsmallsetreplan .plannew-section .accordion .accordion-item .accordion-body .parent-cards .single-card h6{
    margin-top: 15px;
}


@media (max-width:1024px) {
    .plannew-section .accordion .accordion-item .accordion-body .parent-cards {
        grid-template-columns: auto;
    }

    .plannew-section .accordion .accordion-item .accordion-body .parent-cards .last-card {
        max-width: 100%;
        grid-column: auto;
    }

    .plannew-section .accordion .accordion-item .accordion-button {
        padding: 25px 20px;
    }

    .plannew-section .accordion .accordion-item .accordion-button h6 {
        font-size: 14px;
    }

    .plannew-section .accordion .accordion-item .accordion-button h6 span {
        font-size: 14px;
    }

    .plannew-section .accordion .accordion-item .accordion-body {
        padding: 15px;
    }

    .plannew-section .accordion .accordion-item .accordion-body .parent-cards .single-card {
        padding: 20px;
    }

    .plannew-section .accordion .accordion-item .accordion-body .parent-cards .single-card .twice-itemss img {
        width: auto;
        height: auto;
    }

    .plannew-section .accordion .accordion-item .accordion-body .parent-cards .single-card h6 {
        font-size: 18px;
    }

    .plannew-section .accordion .accordion-item .accordion-body .parent-cards .single-card h6 span {
        font-size: 18px;
    }

    .plannew-section .accordion .accordion-item .accordion-body .parent-cards .single-card p {
        font-size: 14px;
    }

    .plannew-section .accordion .accordion-item .accordion-body .parent-cards .single-card img {
        width: 40px;
        height: 40px;
    }

    .plannew-section .accordion .accordion-item .accordion-body .parent-cards .single-card h6 img {
        width: auto;
        height: auto;
    }
}

@media (max-width:600px) {
    .plannew-section .accordion .accordion-item .accordion-body .parent-cards .single-card h6 {
        flex-wrap: wrap;
    }

    .plannew-section .accordion .accordion-item .accordion-body .parent-cards .single-card .twice-text h6 {
        gap: 0 10px;
    }

    .plannew-section {
        padding-top: 20px;
        padding-bottom: 50px;
    }
}

@media (max-width:400px) {
    .plannew-section .accordion .accordion-item .accordion-body .parent-cards .single-card h6 {
        font-size: 16px;
    }

    .plannew-section .accordion .accordion-item .accordion-body .parent-cards .single-card h6 span {
        font-size: 14px;
    }
}
