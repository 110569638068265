.mainexplainer {
    background: #0B0B0B;
    padding-bottom: 147px;
    padding-top: 81px;
    margin-bottom: 15px;
    border: 1px solid #1B1B1B;

    .dopphasesheader {
        height: 69px;
        border-bottom: 1px solid #1F1F1F;
        background: #0B0B0B;
        display: flex;
        align-items: center;
        padding: 0px 30px;

        .dopphasesheaderpara {
            color: #7E7E7E;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.014px;
            text-transform: uppercase;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 7px;
        }
    }

    .blogsinner {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        margin: 0 auto;
        padding-top: 70px;
        gap: 20px;
        flex-direction: column;

        .mainhead {
            h2 {
                color: #FFF;
                font-size: 45px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-transform: uppercase;

                margin-bottom: 23px;
            }
        }
    }

}

.mainnet {

    padding: 0px 72px;
    background: url(../../../../../src/assets/laptop.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 461px;

    .mainhead {
        padding-top: 66px;

        h2 {
            color: #FFF;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            text-transform: uppercase;
            width: 649px;
        }

        p {
            color: #FFF;
            width: 649px;
            ;
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            /* 30px */
            text-transform: uppercase;
            margin-top: 30px;
            margin-bottom: 68px;
        }

        .btn-launch {
            width: 232px;
        }

        h6 {
            white-space: nowrap;
            width: 100%;
            text-align: center;
        }

        svg {
            width: 41px;
        }
    }
}

@media(max-width:600px) {
    .mainnet .mainhead p {
        width: unset;
        font-size: 19px;
    }

    .mainnet .mainhead h2 {
        width: unset;
        font-size: 31px;
    }

    .mainnet {
        background: none !important;
        padding: 0px;
    }

    .mbllap {
        display: block !important;
        width: 654px;
        height: 483px;
    }
    .mobile-img-laptop{
        display: block !important;
    }
    .mainnet{
        height: auto;
    }
}