.mainfaqs {
    background: #000;
    padding-bottom: 147px;
    margin-bottom: 15px;

    .dopphasesheader {
        height: 69px;
        border-bottom: 1px solid #1B1B1B;

        background: #000;
        display: flex;
        align-items: center;
        padding: 0px 30px;

        .dopphasesheaderpara {
            color: #7E7E7E;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.014px;
            text-transform: uppercase;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 7px;
        }
    }

    .upperhead {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 74px;

        .headinggggg {
            color: #FFF;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            /* 36px */
            text-transform: uppercase;
            width: 850px;
            text-align: center;
        }

    }

    .acc {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 58px;

        .accordion {
            background: transparent;
            border: none;
            box-shadow: none;
            outline: none;
            padding: 0px;
            margin: 0px;
            border-radius: 0px;
            max-width: 1040px;
            width: 100%;
            margin: 0 auto;

            .accordion-item {
                box-shadow: none;
                outline: none;
                margin: 0px;
                border-radius: 0px;
                border: 1px solid #2C2C2C;
                background: #000;
                margin-bottom: 12px;
                padding: 20px;

                .accordion-header {
                    background: transparent;
                    border: none;
                    box-shadow: none;
                    outline: none;
                    padding: 0px;
                    margin: 0px;
                    border-radius: 0px;


                    .accordion-button::after {
                        background: url('../../../../assets/accadd.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        padding: 0px;
                        width: 28px;
                        height: 28px;
                    }

                    .accordion-button:not(.collapsed)::after {
                        background: url('../../../../assets/accminus.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        padding: 0px;
                        width: 28px;
                        height: 28px;
                    }

                    .accordion-button {
                        background: transparent;
                        box-shadow: none;
                        outline: none;
                        padding: 0px;
                        margin: 0px;
                        color: #FFF;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                        text-transform: uppercase;
                        border-radius: 0px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 12px;

                        .bold {
                            font-weight: 700;
                        }
                    }
                }

                .accordion-body {
                    background: transparent;
                    box-shadow: none;
                    outline: none;
                    padding: 0px;
                    padding-top: 20px;
                    margin: 0px;
                    border-radius: 0px;
                    color: #CBCBCB;
                    padding-left: 40px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }
}

@media(max-width:1024px) {
    .mainfaqs .upperhead .headinggggg {
        width: unset;
    }

    .mainfaqs .acc .accordion .accordion-item {
        width: unset;
    }
}

@media(max-width:600px) {
    .mainfaqs .upperhead .headinggggg {
        font-size: 14px !important;
        width: 389px;
    }

}