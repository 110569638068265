.visionbanner {
    background: url('../../assets/bgtech.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 464px;
    margin-bottom: 15px;

    .techbannerinner {
        position: relative;
        width: 100%;
        height: 464px;

        .techhead {
            color: #FFF;
            font-size: 84px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            position: absolute;
            bottom: 35px;
            left: 0px;
        }
    }
}



///////.................. Visioncard scsss............////////


.visioncard {
    background: #0B0B0B;
    padding-bottom: 147px;
    margin-bottom: 15px;
    position: relative;
    z-index: 9;
    overflow: hidden;

    .eye-abs {
        position: absolute;
        bottom: -39%;
        left: -258px;
        z-index: -1;
        pointer-events: none;
        width: 800px;
    }

    .dopphasesheader {
        height: 69px;
        border-bottom: 1px solid #1F1F1F;
        background: #0B0B0B;
        display: flex;
        align-items: center;
        padding: 0px 30px;

        .dopphasesheaderpara {
            color: #7E7E7E;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.014px;
            text-transform: uppercase;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 7px;
        }
    }

    .mainparentvision {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 89px;
        position: relative;

        .visionhead {
            color: #FFF;
            text-align: center;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            max-width: 734px;
        }

        .visionpara {
            color: #E1E1E1;
            max-width: 734px;
            text-align: center;
            margin-top: 40px;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
        }
    }
}

/////////............ visionjoin.scss..........////////


.visionjoin {
    padding: 64px 0px 81px;
    background: #0B0B0B;
    position: relative;

    .joinussvg {
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 410px;
        height: 380px;
    }

    .mainheadingsss {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;

        .joinhead {
            color: #FFF;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            text-transform: uppercase;
            margin-bottom: 49px;
            max-width: 649.498px;
            width: 100%;
        }

        .btn-launch {
            width: 141px;
        }

        h6 {
            white-space: nowrap;
        }

        svg {
            width: 41px;
        }
    }
}


@media (max-width:992px) {
    .visionbanner .techbannerinner .techhead {
        font-size: 49px;
        bottom: 30px;
    }

    .visionbanner {
        height: 248px;
     
    }

    .visionbanner .techbannerinner {
        height: 248px;
    }

    .visioncard .mainparentvision .visionhead {
        font-size: 22px;
        width: 378px;
    }

    .visioncard .mainparentvision .visionpara {
        font-size: 16px;
        width: 378px;
    }

    .visioncard .mainparentvision {
        padding-top: 66px;
    }

    .visioncard .eye {
        width: 380px;
        bottom: -26px;
    }
    .visioncard .mainparentvision .visionhead{
        width: 100%;
    }
    .visioncard .mainparentvision .visionpara{
        width: 100%;
    }
    .visioncard{
        padding-bottom: 200px;
    }
}

@media (max-width:600px){
    .visioncard .eye-abs{
        width: 500px;
        position: absolute;
        bottom: -19%;
        left: -142px;
        z-index: -1;
        pointer-events: none;
    }
    .visionjoin{
        padding-bottom: 150px;
    }
    .visionjoin .joinussvg{
        width: 186px;
        height: 184px;
    }
    .visionjoin .mainheadingsss .joinhead{
        font-size: 20px !important;
    }
}