@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');


html {
  scroll-behavior: smooth;
}

body {
  font-family: "Space Mono", monospace;
  background: #000;
  height: 100vh;
  padding: 15px;
  cursor: url('../src/assets/newsvgcursor.svg'), auto;

  &::-webkit-scrollbar {
    width: 5px;
  }


  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }


  &::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 10px;
  }


  &::-webkit-scrollbar-thumb:hover {
    background: #808080;
  }
}

.roadmapopen-bodypaddingzero{
  padding: 0 !important;
}

.custom-container {
  max-width: 1430px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}




.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 0.7s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
  height: 60px;
  width: 100%;
}

a {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}


.grey {
  color: #888 !important;
}



.ptb20 {
  padding: 20px 0;
}




.ptb60 {
  padding: 60px 0;
}






.cursor-pointer {
  cursor: pointer;
}

.purple {
  color: #6c01c3;
}

.yellow {
  color: #ffc13d;
}

.border-img {
  border-radius: 10px;
}

.common {
  color: #ff0083;
}

.green {
  color: #6dff39;
}

.red {
  color: #f41e5e;
}

.white {
  color: #fff !important;
}

.blue {
  color: #133572;
}

.navbar-toggle {
  background-color: #333;

  .icon-bar {
    background-color: #eee;
  }
}


@media only screen and (max-width: 600px) {
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    outline: none;

    &:focus {
      outline: none;
    }
  }

  .main-footer {
    text-align: center;

    .text-right {
      text-align: center !important;
    }
  }

  h1 {
    font-size: 2.5rem !important;
  }

  .ptb {
    padding: 30px 0;
  }

  .xs-0 {
    padding: 0px;
  }

  .main-mint,
  .main-banner {
    .inner-content {
      .modal-content {
        .for-padding {
          padding: 0 0px !important;
        }
      }
    }
  }

  .main-give {
    .right-side {
      margin-top: 30px !important;

      h5 {
        font-weight: 500;
      }

      li {
        margin-right: 2px !important;
      }
    }
  }
}

// 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {

  h1 {
    font-size: 3rem !important;
  }

  .ptb {
    padding: 20px 0;
  }
}



iframe {
  position: unset !important;
  top: 0px !important;
  left: 0px !important;
  width: 0px !important;
  height: 0px !important;
}

button {
  cursor: pointer;
}

@media (max-width: 600px) {
  h2 {
    font-size: 30px !important;
  }

  .col-md-6 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-8 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-4 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

button:focus {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

.form-control:focus {
  box-shadow: unset;
}


@media(max-width:600px) {
  .modal.show .modal-dialog {
    max-width: 100% !important;
  }
}

button {
  cursor: pointer;
}

a {
  cursor: pointer;
}

button,
input,
optgroup,
select,
textarea:focus-visible {
  outline: none !important;

}

button,
input,
optgroup,
select,
textarea:focus {
  outline: none !important;

}

button.close {
  opacity: 1;
}



.btn-launch {
  border: 1px solid #FFF;
  background: #000;
  box-shadow: 2px 2px 0px 0px #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  h6 {
    padding: 10px 12px;
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    border-right: 1px solid #FFF;
    background: #000;
  }

  svg {
    width: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}






.Appp {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.containerr {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  width: 100vw;
}

.sectionn {
  flex-shrink: 0;
  flex-grow: 0;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
}

.vertical-scrolll {
  overflow-y: auto;
  overflow-x: hidden;
}

.horizontal-scrolll {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.itemm {
  flex: 0 0 auto;
  width: 200px;
  /* Adjust width as needed */
  height: 200px;
  /* Adjust height as needed */
  margin: 10px;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iframemodal {


  .modal-dialog {
    max-width: 60vw !important;

    .modal-content {
      padding: 20px;

      .modal-header {
        padding: 20px;
        margin-bottom: 20px;
      }

      .modal-body {
        padding: 0px;

        .youtubeiframe {
          width: 100% !important;
          height: 60vh !important;
          border: 1px solid #FFF;
          background: #FFF;
          box-shadow: 5px 5px 0px 0px #FFF;
        }
      }
    }
  }
}

.dop-community-video {
  .mainimagessss {
    border: 1px solid #FFF;
    background: #FFF;
    box-shadow: 5px 5px 0px 0px #FFF;
    position: relative;
    z-index: 9;

    span {
      background: rgba(0, 0, 0, 0.70);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }

    .playvideo-btn {
      position: absolute;
      width: auto !important;
      z-index: 5;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.mblmenu .offcanvas-body .bottomparent .innerparent .forblack .toggle-input:checked+.toggle-label {
  background-color: #000 !important;
}

.disablelable::before {
  transform: translateX(20px) !important;
}

.modal-backdrop {
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(4px);
}

.nav-link:focus-visible {
  box-shadow: none !important;
}




// material text field input scss style............................


.material-textfield {
  position: relative;
  width: 100%;

  .search-icon {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(0%, -50%);
  }
}

.material-textfield label {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  // background-color: #fff;
  background-color: transparent;
  padding: 17px 6px 17px 18px;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 120%;
  color: #000;
  text-transform: capitalize;

}

.material-textfield input {
  outline: none;
  transition: 0.1s ease-out;
  border: 1px solid #DFDFDF;
  // background: #fff;
  background-color: transparent;
  border-radius: 0px;
  padding: 17px 18px;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 117%;
  width: 100%;
  box-shadow: none !important;
  color: #000;

  &::placeholder {
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 117%;
    color: #343434;
    opacity: 0;
    text-transform: capitalize;
  }
}

.material-textfield .label-textarea {
  position: absolute;
  left: 0;
  top: 20%;
  transform: translateY(-50%);
  background-color: transparent;
  padding: 17px 6px 17px 18px;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 120%;
  color: #000;

}

.material-textfield textarea {
  outline: none;
  transition: 0.1s ease-out;
  border: 1px solid #DFDFDF;
  background-color: transparent;
  border-radius: 0px;
  padding: 17px 18px;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 117%;
  width: 100%;
  box-shadow: none !important;
  color: #000;
  height: 132px;

  &::placeholder {
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 117%;
    color: #343434;
    opacity: 0;
    text-transform: capitalize;
  }
}

.searchasset-input {
  border: 1px solid #000 !important;
  background: #fff !important;
}



.material-textfield input:focus+label {
  top: 0;
  left: 6px;
  transform: translateY(-50%) scale(0.9);
  padding: 0 0.3rem;
  font-weight: 700 !important;
  background: #fff !important;
  background-color: #fff !important;
  text-transform: capitalize;

}

.material-textfield input:focus::placeholder {
  opacity: 1;
}

.material-textfield textarea:focus+label {
  top: 0;
  left: 6px;
  transform: translateY(-50%) scale(0.9);
  padding: 0 0.3rem;
  font-weight: 700 !important;
  background: #fff !important;
  background-color: #fff !important;
  text-transform: capitalize;

}

.material-textfield textarea:focus::placeholder{
  opacity: 1;
}

.material-textfield input:not(:placeholder-shown)+label {
  top: 0;
  transform: translateY(-50%) scale(0.9);
  padding: 0 0.4rem 0 0.3rem;
  font-weight: 700 !important;
  background-color: #fff !important;

}

.material-textfield textarea:not(:placeholder-shown)+label {
  top: 0;
  transform: translateY(-50%) scale(0.9);
  padding: 0 0.4rem 0 0.3rem;
  font-weight: 700 !important;
  background-color: #fff !important;

}


.custom-checkbox {
  .form-group {
    display: block;
  }

  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .form-group label {
    position: relative;
    cursor: pointer;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  .form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #535353;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }

  .form-group input:checked+label:before {
    content: '';
    background-color: #000;
  }

  .form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}



input[type="date"], input[type="time"] {
  -webkit-appearance: none !important;
  -moz-appearance: none;
  appearance: none !important;
}