.main-banner {
  min-height: calc(100vh - 79px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 99;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: url("../../../assets/banner-bg.png") no-repeat;
    background-size: cover; 
    transform: scale(1.02); 
    animation: animatedBackground 10s linear infinite alternate;
    z-index: -1;
  }
  
  @keyframes animatedBackground {
    0% {
      transform: scale(1.02) translateX(0); 
    }
  
    100% {
      transform: scale(1.02) translateX(-2.5vw); 
    }
  }

  .banner-model {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    width: 297px;
  }

  .banner-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    height: 100vh;
    pointer-events: none;
    object-fit: cover;
  }

  .main-content {
    max-width: 494px;
    width: 100%;

    h5 {
      color: #fff;
      font-size: 54px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      text-transform: uppercase;
      margin-bottom: 16px;
    }

    p {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      margin-bottom: 36px;
    }

    .twice-btns {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;

      .btn-transparent {
        color: #fff;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }
  .cookies {
    background: #fff;
    padding: 22px 20px;
    max-width: 315px;
    width: 100%;
    position: absolute;
    bottom: 35px;
    right: 15px;
    .cookiestext {
      h4 {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        text-transform: capitalize;
        margin-bottom: 10px;
      }
      p {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        span {
          color: #000;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 130%;
          text-decoration-line: underline;
          cursor: pointer;
        }
      }
      .cookiesbutton {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        margin-top: 25px;
        .accept {
          border: 1px solid #000;
          background: #fff;
          box-shadow: 2px 2px 0px 0px #000;
          padding: 7px 0px;
          width: 100%;
          color: #000;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .manage {
          background: #f1f1f1;
          padding: 7px 0px;
          width: 100%;
          color: #000;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border: none;
        }
      }
    }
  }
}

.mblmenu {
  background: #fff;
  padding: 22px 20px;
  min-height: 650px;
  max-height: 650px;
  width: 362px;
  top: unset !important;
  bottom: 30px;
  right: 30px !important;
  overflow: hidden;
  .offcanvas-header {
    padding: 0px;
    .offcanvas-title {
      color: #000;
      font-feature-settings: "clig" off, "liga" off;
      font-family: "Space Mono";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      img {
        cursor: pointer;
      }
    }
  }
  .offcanvas-header .btn-close {
    display: none;
  }
  .offcanvas-body {
    padding: 0px;
    .maintext {
      h4 {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        width: 322px;
        margin-top: 10px;
        span {
          color: #000;
          font-feature-settings: "clig" off, "liga" off;
          font-family: "Space Mono";
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 130%;
          text-decoration-line: underline;
        }
      }
    }
    .brdr {
      background: rgba(0, 0, 0, 0.1);
      height: 1px;
      width: 100%;
      margin: 15px 0px;
    }
    .bottomparent {
      p {
        color: #535353;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        width: 265.33px;
      }
      .innerparent {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h6 {
          color: #000;

          font-feature-settings: "clig" off, "liga" off;
          font-family: "Space Mono";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%; /* 12px */
          text-transform: capitalize;
        }

        .toggle-switch {
          position: relative;
          display: inline-block;
          width: 40px;
          height: 24px;
          margin: 10px;
        }

        /* Giriş stil */
        .toggle-switch .toggle-input {
          display: none;
        }

        /* Anahtarın stilinin etrafındaki etiketin stil */
        .toggle-switch .toggle-label {
          position: absolute;
          top: 0;
          left: 0;
          width: 44px;
          height: 24px;
          background: var(--Yoda-Admin-Template-Blacks-B-60, #B2BEC3);
          border-radius: 16px;
          cursor: pointer;
          transition: background-color 0.3s;
        }

        /* Anahtarın yuvarlak kısmının stil */
        .toggle-switch .toggle-label::before {
          content: "";
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          top: 2px;
          left: 2px;
          background: #ffffff;
          transition: transform 0.3s;
        }

        /* Anahtarın etkin hale gelmesindeki stil değişiklikleri */
        .toggle-switch .toggle-input:checked + .toggle-label {
          background: #a7a7a7;
        }

        .toggle-switch .toggle-input:checked + .toggle-label::before {
          transform: translateX(20px);
        }

        /* Light tema */
        .toggle-switch.light .toggle-label {
          background-color: #bebebe;
        }

        .toggle-switch.light .toggle-input:checked + .toggle-label {
          background-color: #9b9b9b;
        }

        .toggle-switch.light .toggle-input:checked + .toggle-label::before {
          transform: translateX(6px);
        }

        /* Dark tema */
        .toggle-switch.dark .toggle-label {
          background-color: #4b4b4b;
        }

        .toggle-switch.dark .toggle-input:checked + .toggle-label {
          background-color: #717171;
        }

        .toggle-switch.dark .toggle-input:checked + .toggle-label::before {
          transform: translateX(16px);
        }
      }
    }
    .savebtnss {
      margin-top: 25px;
      .save {
        border: 1px solid #000;
        background: #fff;
        box-shadow: 2px 2px 0px 0px #000;
        padding: 7px 0px;
        max-width: 178px;
        width: 100%;
        color: #000;
        font-family: "Space Mono";
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0 auto;
        display: block;
      }
    }
  }
}

@media (max-width: 1024px) {
  .main-banner::before{
    background: unset !important;
    position: unset;
    width: unset;
    height: unset;
    transform: unset;
    animation: unset;
  }
  .main-banner {
    align-items: flex-start;
    padding-top: 88px;
  }
  .main-banner .main-content h5 {
    font-size: 37px;
  }

  .main-banner .banner-bg {
    display: none;
  }

  .main-banner .banner-bg-mbl {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    height: 100%;
    pointer-events: none;
    object-fit: cover;
    display: block !important;
  }
  .main-banner .banner-model {
    display: none;
  }
  .main-banner {
    overflow: visible !important;
  }
}

@media (max-width: 600px) {
  .mblmenu {
    width: 100% !important;
    min-height: 100vh !important;
    top: 0 !important;
    bottom: unset !important;
    right: 0 !important;
    min-width: 100% !important;
  }
  .main-banner .cookies{
    left: 50%;
    transform: translate(-50%, 0%);
    right: unset;
  }

}
