.doporacle-section {
    padding-top: 113px;
    padding-bottom: 133px;
    overflow: hidden;
    position: relative;
    z-index: 99;
    margin-bottom: 15px;
    border: 1px solid #3A3A3A;



    .oracle-bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        pointer-events: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .main-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 790px;
        width: 100%;
        margin: 0 auto;

        h4 {
            color: #FFF;
            text-align: center;
            font-size: 44px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            margin-top: 42px;
            margin-bottom: 30px;
            max-width: 490px;
        }

        p {
            color: #E1E1E1;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            margin-bottom: 42px;
        }

        a {
            border: 1px solid #FFF;
            background: #8F56FF;
            box-shadow: 2px 2px 0px 0px #FFF;
            padding: 16px 30px;
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            text-transform: uppercase;
        }
    }
}

.oracle-howitworks {
    .mainparentvision {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 49px;
        flex-direction: row;

        .left-side {
            max-width: 583px;
            width: 100%;

            .visionhead {
                color: #FFF;
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-align: start;
            }

            .visionpara {
                text-align: start;
                color: #9A9A9A;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
            }
        }

        .right-side {
            flex: 1;

            .single-box {
                border: 1px solid #FFF;
                background: #0B0B0B;
                box-shadow: 2px 2px 0px 0px #FFF;
                padding: 20px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 20px;
                margin-bottom: 12px;

                .inside-left {
                    h6 {
                        color: #8F56FF;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                        text-transform: uppercase;
                        white-space: nowrap;
                    }
                }

                .inside-right {
                    h6 {
                        color: #FFF;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%;
                        margin-bottom: 15px;
                    }

                    p {
                        color: #9A9A9A;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 130%;
                    }
                }
            }
        }
    }
}

.whydoporacle {
    .main-head {
        margin: 72px 0;

        h4 {
            color: #FFF;
            text-align: center;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: capitalize;
        }
    }

    .bottom-cards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 15px;

        .single-card {
            border: 2px solid #1F1F1F;
            background: #0B0B0B;
            padding: 28px;

            h6 {
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                margin-top: 35px;
                margin-bottom: 12px;
            }

            p {
                color: #9A9A9A;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
            }
        }
    }
}

.becomeadoporacle {
    background-color: #fff;
    padding: 50px 0px;
    padding-bottom: 129px;

    .parent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 220px;

        .left-side {
            max-width: 451px;
            width: 100%;

            h4 {
                color: #000;
                font-size: 34px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                margin-bottom: 15px;
            }

            p {
                color: #494949;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
            }
        }

        .right-side {
            flex: 1;

            .main-content {
                h6 {
                    color: #000;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    margin-bottom: 25px;
                }

                .option-field {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 10px;

                    input {
                        flex: 1;
                        border: 1px solid #C6C6C6;
                        padding: 16px;
                        color: #000;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                        transition: 0.3s linear;

                        &::placeholder {
                            color: #BEBEBE;
                        }

                        &.error {
                            border: 1px solid #E82D2D;
                        }

                        &:focus {
                            border: 1px solid #000;
                        }
                    }

                    a {
                        max-width: 185px;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid #000;
                        background: #8F56FF;
                        box-shadow: 2px 2px 0px 0px #000;
                        padding: 16px;
                        color: #FFF;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                    }
                }

                .error-mesg {
                    color: #E82D2D;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    margin-top: 15px;
                }

                .custom-checkbox {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 15px;
                    margin-top: 25px;

                    .form-group input:checked+label:before {
                        background-color: #8F56FF;
                        border: 1px solid transparent;
                    }
                }
            }
        }
    }

}

.oracle-modal {
    .modal-dialog {
        max-width: 410px;
    }

    .modal-content {
        border: 1px solid #727272;
        background: #000;
        padding: 25px;
    }

    .modal-body {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h4 {
            color: #FFF;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            margin: 30px 0;
        }

        a {
            border: 1px solid #FFF;
            background: #8F56FF;
            box-shadow: 2px 2px 0px 0px #FFF;
            padding: 16px 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            text-transform: uppercase;
        }
    }
}

@media (max-width:600px){
    .doporacle-section .main-content h4{
        font-size: 28px;
    }
    .doporacle-section .main-content p{
        font-size: 12px;
    }
    .oracle-howitworks .mainparentvision{
        flex-direction: column;
    }
    .whydoporacle .bottom-cards{
        grid-template-columns: 1fr;
    }
    .becomeadoporacle .parent{
        flex-direction: column;
        gap: 30px;
    }
    .oracle-howitworks{
        padding-bottom: 40px;
    }
    .oracle-howitworks .mainparentvision .right-side .single-box{
        flex-direction: column;
    }
    .whydoporacle .main-head h4{
        font-size: 22px;
    }
    .oracle-howitworks .mainparentvision .left-side .visionhead{
        font-size: 30px;
    }
    .becomeadoporacle .parent .left-side h4{
        font-size: 28px;
    }
    .becomeadoporacle .parent .right-side .main-content h6{
        font-size: 16px;
    }
    .becomeadoporacle .parent .right-side .main-content .custom-checkbox{
        gap: 8px;
    }
    .becomeadoporacle .parent .right-side .main-content .custom-checkbox label{
        font-size: 13px;
    }
    .becomeadoporacle .parent .right-side .main-content .option-field{
        flex-direction: column;
    }
    .becomeadoporacle .parent .right-side .main-content .option-field input{
        width: 100%;
        flex: auto;
    }
    .becomeadoporacle .parent .right-side .main-content .option-field a{
        width: 100%;
        max-width: 100%;
    }
    .whydoporacle{
        padding-bottom: 40px;
    }
    .doporacle-section .main-content img{
        max-width: 191px;
        width: 100%;
    }
    .doporacle-section .oracle-bg{
        object-position: right;
    }
}