.dopgrant-section {
    .parenttexts {
        .left {
            .head-text {
                color: #000;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
                margin-bottom: 20px;
            }
            .btn-launch{
                margin-top: 30px;
            }
        }
    }

    .application-program {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 80px;
        max-width: 1272px;
        width: 100%;
        margin: 0 auto;

        .left-side {
            max-width: 608px;
            width: 100%;

            h4 {
                color: #000;
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-transform: uppercase;
                margin-bottom: 20px;
            }

            p {
                color: #494949;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }
        }

        .right-side {
            flex: 1;

            .inside-items {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 40px;
                margin-bottom: 16px;

                .text {
                    h6 {
                        color: #000;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 100%;
                        letter-spacing: -0.4px;
                        margin-bottom: 16px;
                    }

                    p {
                        color: #494949;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: -0.016px;
                    }
                }
            }
        }
    }

}

.dopgrant-sectionabove {
    background: #000;
    border: 1px solid #1B1B1B;
    border-top: 1px solid transparent;
    padding-bottom: 82px;

    .top-left-text {
        border: 1px solid #1B1B1B;
        background: #000;
    }

    .top-left-text p {
        color: #6C6C6C;
    }

    .parenttext .left p {
        color: #fff;
        border-left: 1px solid #fff;
    }

    .parenttext .right p {
        color: #9D9D9D;
    }

    .parenttexts .left h6 {
        color: #fff;
    }

    .parenttexts .left p {
        color: #9D9D9D;
    }

    .parenttexts {
        margin-bottom: 0;
        .right{
            flex: 1;
            text-align: center;
        }
    }

    .parenttexts .right .mainimg {
        max-width: none;
        max-height: none;
        object-fit: none;
        object-position: center;
        overflow: auto;
    }

    .parenttexts .right .mainimg .images {
        width: auto !important;
        height: auto !important;
        object-fit: contain;
        object-position: center;
    }
}

.usecasesmainforgrant {
    .bottom-part {
        padding-top: 50px;

        .main-heading {
            max-width: 608px;
            width: 100%;
            margin: 0 auto;
            margin-bottom: 40px;

            h6 {
                color: #FFF;
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-transform: uppercase;
                margin-bottom: 20px;
                text-align: center;
            }

            p {
                color: #9D9D9D;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
            }
        }

        .bottom-cards {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 15px;
            max-width: 1074px;
            width: 100%;
            margin: 0 auto;

            .single-card {
                border: 1px solid #1F1F1F;
                background: #0B0B0B;
                padding: 30px;

                h6 {
                    color: #9A9A9A;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;

                    span {
                        color: #FFF;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 140%;
                    }
                }
            }
        }
    }
}


.supportandresource-sec {
    .parent {
        display: grid;
        grid-template-columns: 1fr;
        gap: 15px;
        border: 1px solid #1B1B1B;
        background: #000;
        padding: 46px 70.5px 48px 70px;

        .left-side {
            max-width: 1200px;
            width: 100%;
            margin: 0 auto;



            .main-heading {
                margin-bottom: 24px;

                h6 {
                    color: #FFF;
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    text-transform: uppercase;
                    margin-bottom: 30px;
                }

                p {
                    color: #9A9A9A;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                }
            }

            .inside-points {
                border: 1px solid #1F1F1F;
                margin-bottom: 24px;

                .single-point {
                    border-bottom: 1px solid #1F1F1F;
                    background: #000;
                    padding: 15px;

                    &:last-child {
                        border-bottom: unset;
                    }

                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 15px;

                    h6 {
                        color: #FFF;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 140%;
                    }

                    p {
                        color: #9A9A9A;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                    }
                }
            }

            .para {
                color: #9A9A9A;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }

        }

        .right-side {
            border: 1px solid #1B1B1B;
            background: #000;
            padding: 46px 72.5px 139px 68px;

            .main-heading {
                margin-bottom: 25px;

                h6 {
                    color: #FFF;
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    text-transform: uppercase;
                }
            }

            .inside-form {
                .option-field {
                    margin-bottom: 12px;

                    input {
                        border: 1px solid #2C2C2C;
                        background: #000;
                        padding: 22px;
                        color: #fff;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: uppercase;
                        width: 100%;

                        &::placeholder {
                            color: #767676;
                        }

                    }

                    .dropdown {
                        width: 100%;

                        .dropdown-toggle {
                            border: 1px solid #2C2C2C;
                            background: #000;
                            padding: 22px;
                            color: #767676;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            text-transform: uppercase;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;

                            &::after {
                                display: none;
                            }
                        }

                        .dropdown-menu {
                            border: 1px solid #2C2C2C;
                            background: #000;
                            border-radius: 0;
                            width: 100%;

                            a {
                                padding: 22px;
                                color: #767676;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                text-transform: uppercase;

                                &:hover {
                                    background-color: transparent;
                                    color: #767676;
                                }
                            }
                        }
                    }
                }

                .upload-div {
                    border: 1px solid #2C2C2C;
                    background: #000;
                    padding: 22px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    cursor: pointer;
                    margin-bottom: 20px;

                    h6 {
                        color: #FFF;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: uppercase;
                        margin-top: 13px;
                    }

                    p {
                        color: #747474;
                        font-size: 11px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: uppercase;
                    }
                }

                .btnwhite {
                    border: 1px solid #0B0B0B;

                    background: #fff;

                    box-shadow: 2px 2px 0px 0px #FFF;
                    padding: 0px;
                    max-width: fit-content;
                    display: flex;
                    align-items: center;
                    flex-direction: row;

                    width: 100%;

                    .image {
                        border-left: 1px solid #000;
                        display: flex;
                        padding: 13px 20px;
                        justify-content: center;
                        align-items: center;
                    }

                    .btntext {
                        color: #0B0B0B;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: uppercase;
                        display: flex;
                        padding: 7px 30px;
                        justify-content: center;
                        align-items: center;
                        width: 548px;
                    }

                }
            }

        }
    }
}


@media (max-width:1024px) {
    .usecasesmainforgrant .bottom-part .bottom-cards {
        grid-template-columns: 1fr;
    }

    .dopgrant-section .application-program {
        flex-direction: column;
    }

    .supportandresource-sec .parent {
        grid-template-columns: 1fr;
    }

    .dopgrant-section {
        padding-bottom: 40px;
    }

    .usecasesmainforgrant .bottom-part .main-heading h6 {
        font-size: 20px;
    }

    .usecasesmainforgrant .bottom-part .main-heading p {
        font-size: 14px;
    }

    .usecasesmainforgrant .bottom-part .bottom-cards .single-card h6 {
        font-size: 16px;
    }

    .usecasesmainforgrant .bottom-part .bottom-cards .single-card h6 span {
        font-size: 16px;
    }

    .dopgrant-section .application-program .left-side h4 {
        font-size: 20px;
        text-align: center;
    }

    .dopgrant-section .application-program .left-side p {
        font-size: 14px;
        text-align: center;
    }

    .dopgrant-section .top-left-text {
        margin-bottom: 40px;
    }

    .dopgrant-section .application-program {
        gap: 50px;
    }

    .dopgrant-section .application-program .right-side .inside-items {
        gap: 30px;
    }

    .dopgrant-section .application-program .right-side .inside-items .text h6 {
        font-size: 16px;
    }

    .dopgrant-section .application-program .right-side .inside-items .text p {
        font-size: 14px;
    }

    .supportandresource-sec .parent {
        padding: 20px 15px;
    }

    .supportandresource-sec .parent .left-side .main-heading h6 {
        font-size: 26px;
    }

    .supportandresource-sec .parent .left-side .main-heading p {
        font-size: 14px;
    }

    .supportandresource-sec .parent .right-side {
        padding: 20px 15px;
    }

    .supportandresource-sec .parent .right-side .main-heading h6 {
        font-size: 26px;
    }

    .supportandresource-sec .parent .right-side .inside-form .btnwhite .image {
        width: 57px;
        height: 48px;
    }

    .supportandresource-sec .parent .right-side .inside-form .btnwhite .btntext {
        width: 1024px;
    }
    .dopgrant-section .parenttexts .left .btn-launch{
        width: 100%;
    }
}

.set-btn-white {
    border: 1px solid #0B0B0B;
    background: #FFF;
    box-shadow: 2px 2px 0px 0px #000;

    h6 {
        flex: 1;
        background-color: #fff !important;
        color: #000;
        border-right: 1px solid #0B0B0B;
        background: #FFF;
    }
}

.dopgrantapplicationopening {
    margin-top: 15px;

    .mainheadingsss .joinhead {
        margin-bottom: 13px;
    }

    .mainheadingsss {
        p {
            color: #FFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            margin-bottom: 40px;
            max-width: 650px;
            width: 100%;
        }
    }
}

@media (max-width:600px) {
    .dopgrantapplicationopening .mainheadingsss p {
        font-size: 16px;
    }
}