.maincontact{
    background: url('../../../assets/bgtech.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 464px;
    margin-bottom: 15px;

    .techbannerinner {
        position: relative;
        width: 100%;
        height: 464px;

        .techhead {
            color: #FFF;
            font-size: 84px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            position: absolute;
            bottom: 35px;
            left: 0px;
        }
    }
}

@media (max-width:992px){
    .maincontact .techbannerinner .techhead{
        font-size: 49px;
        bottom: 30px;
    }
    .maincontact{
        height: 248px;
       
    }
    .maincontact .techbannerinner{
        height: 248px;
    }

}