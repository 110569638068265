.dopcommunitycards {
    border: 1px solid #1B1B1B;
    background: #000;
    padding-bottom: 130px;
    margin-bottom: 15px;

    .dopcommunitycardsheader {
        height: 69px;
        border-bottom: 1px solid #1B1B1B;
        background: #000;
        display: flex;
        align-items: center;
        padding: 0px 30px;

        .dopcommunitycardsheaderpara {
            color: #6C6C6C;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.014px;
            text-transform: uppercase;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 7px;
        }
    }

    .dopcommunityvideo {
        padding-top: 75px;

        .dopcommunityvideohead {
            color: #000;
            text-align: center;
            font-size: 45px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            margin-bottom: 50px;
        }

        .video-player {
            max-width: 1200px;
            margin: 0 auto;
            width: 100%;
            position: relative;
            border: 1px solid #FFF;
            background: #FFF;
            box-shadow: 5px 5px 0px 0px #FFF;
            height: 675.5px;

            &:hover {
                .pause-button-main {
                    display: flex !important;
                }
            }

            .play-button-main {
                background: rgba(0, 0, 0, 0.70);
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                display: flex;
                justify-content: center;
                align-items: center;

                .play-button {
                    border: 1px solid #000;
                    background: #FFF;
                    box-shadow: 5px 5px 0px 0px #000;
                    display: flex;
                    padding: 12px 24px 12px 12px;
                    align-items: center;
                    justify-content: center;
                    color: #000;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    text-transform: uppercase;
                }
            }

            .pause-button-main {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                display: none;
                justify-content: center;
                align-items: center;

                .play-button {
                    border: 1px solid #000;
                    background: #FFF;
                    box-shadow: 5px 5px 0px 0px #000;
                    display: flex;
                    padding: 12px 24px 12px 12px;
                    align-items: center;
                    justify-content: center;
                    color: #000;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    text-transform: uppercase;
                }
            }

            .video-player-video {
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .communitymainhead {
        color: #FFF;
        text-align: center;
        font-size: 45px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
        margin-top: 70px;
        margin-bottom: 30px;
    }

    .communitymain {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;

        .communityinner {
            display: flex;
            padding: 18px 24px 18px 20px;
            justify-content: center;
            align-items: center;
            gap: 16px;
            border: 1px solid #FFF;
            background: #000;
            width: 100%;
            position: relative;

            .communityinnerpara {
                color: #FFF;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-transform: uppercase;
            }

            .dropdown-toggle {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 16px;
                width: 100%;
                background-color: transparent;
                border: none;
                box-shadow: none;

                &::after {
                    display: none;
                }
            }

            .dropdown-menu {
                padding: 0;
                width: 100%;
                left: 0 !important;
                right: unset !important;
                transform: unset !important;
                top: 80px !important;
                border: 1px solid #FFF;
                box-shadow: 2px 2px 0px 0px #FFF;
                border-radius: 0;

                a {
                    border-bottom: 1px solid #1F1F1F;
                    background: #000;
                    padding: 12px 15px;
                    color: #9D9D9D;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: uppercase;
                    transition: 0.3s linear;

                    &:hover {
                        color: #FFF;
                        border-bottom: 1px solid #1F1F1F;
                        background: #0A0A0A;
                    }
                }
            }
        }
    }
}

.dop-community-video {
    padding: 0 !important;
    background-color: transparent !important;
    border: none !important;

    .blogsinner {
        padding-top: 0 !important;
    }
}

.offical-community-links {
    .inlineflex-btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 750px;
        width: 100%;
        gap: 20px;
        margin: 0 auto;

        .btn-launch {
            width: 100%;
        }

        .btn-launch h6 {
            max-width: 178px;
            width: 100%;
            text-align: center;
            padding: 15px 40px;
        }

        .btn-launch svg {
            width: 60px;
        }
    }
}

@media(max-width:992px) {
    .dopcommunitycards .dopcommunityvideo {
        padding-top: 40px;
    }

    .dopcommunitycards .dopcommunityvideo .video-player {
        height: 217.849px;
    }

    .dopcommunitycards .dopcommunityvideo .video-player .play-button-main .play-button {
        font-size: 8.407px;
        padding: 3.88px 7.76px 3.88px 3.88px;

        svg {
            width: 19.4px;
            height: 19.4px;
        }
    }

    .dopcommunitycards .dopcommunityvideo .video-player .pause-button-main .play-button {
        font-size: 8.407px;
        padding: 3.88px 7.76px 3.88px 3.88px;

        svg {
            width: 19.4px;
            height: 19.4px;
        }
    }

    .dopcommunitycards .communitymainhead {
        margin-top: 40px;
        font-size: 20px;
    }

    .dopcommunitycards {
        padding-bottom: 63px;
    }

    .offical-community-links .inlineflex-btns {
        flex-direction: column;
    }

    .offical-community-links .inlineflex-btns .btn-launch h6 {
        max-width: 100%;
    }

    .dop-community-video .blogsinner .mainhead h2 {
        font-size: 20px !important;
    }

    .dop-community-video {
        padding-left: 15px !important;
    }
}


.dop-community-video {
    .mainimagessss {
        border: 1px solid #FFF;
        background: #FFF;
        box-shadow: 5px 5px 0px 0px #FFF;
        position: relative;
        z-index: 9;
        cursor: pointer;
        span{
            background: rgba(0, 0, 0, 0.70);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
        }
        .playvideo-btn{
            position: absolute;
            width: auto !important;
            z-index: 5;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}