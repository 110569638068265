.dopcreator {
    // background: url('../../../assets/bgtech.png');
    background: url(../../../src/assets/bgtech.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 464px;
    margin-bottom: 15px;

    // padding-bottom: 171px;
    .techbannerinner {
        position: relative;
        width: 100%;
        height: 464px;

        .techhead {
            color: #FFF;
            font-size: 84px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            position: absolute;
            bottom: 35px;
            left: 0px;
        }
    }
}

.brand {
    padding-bottom: 171px;

    .dopphasesheader {
        height: 69px;
        border-bottom: 1px solid #1F1F1F;
        background: #0B0B0B;
        display: flex;
        align-items: center;
        padding: 0px 30px;

        .dopphasesheaderpara {
            color: #7E7E7E;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.014px;
            text-transform: uppercase;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 7px;
        }
    }

    .parenttextxx {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 75px;
        flex-direction: column;

        h2 {
            color: #FFF;

            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            margin-bottom: 20px;
        }

        p {
            color: #E1E1E1;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
        }

        h5 {
            color: #E1E1E1;
            margin-top: 50px;
            margin-bottom: 20px;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            /* 21.6px */
            text-transform: uppercase;
        }

        .parentinput {
            width: 200px;

            h4 {
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
            }

            
            .btn-launch h6{
                width: 100%;
                text-align: center;
            }
            .btn-launch svg{
                width: 42px;
            }
        }

    }
}

/////////...............brand scss..........////////



@media (max-width:992px) {
    .dopcreator .techbannerinner .techhead {
        font-size: 49px !important;
        bottom: 30px;
    }

    .dopcreator {
        height: 248px;
   
    }

    .dopcreator .techbannerinner {
        height: 248px;
    }

    .brand .parenttextxx h2 {
        font-size: 20px !important;
    }

    .brand .parenttextxx p {
        font-size: 14px;
        width: 353px;
        text-align: center;
    }

    .brand .parenttextxx .parentinput {
        width: 100%;
    }

    .brand .parenttextxx .parentinput h4 {
        font-size: 13px;
    }

    .brand .parenttextxx .parentinput .btn-launch {
        top: 12px;
    }



}

@media (max-width:390px){
    .brand .parenttextxx .parentinput{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 25px;
    }
    .brand .parenttextxx .parentinput .btn-launch{
        position: static;
    }
}