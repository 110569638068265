.mainblog{
    background: #0B0B0B;
    padding-bottom: 147px;
    margin-bottom: 15px;
   
    .dopphasesheader {
        height: 69px;
        border-bottom: 1px solid #1F1F1F;
        background: #0B0B0B;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 30px;

        .dopphasesheaderpara {
            color: #7E7E7E;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.014px;
            text-transform: uppercase;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 7px;
        }
    }
    .blogsinner{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
  
        margin: 0 auto;
        padding-top: 70px;
        gap: 20px;
        flex-direction: column;
        .mainhead{
            h2{
                color: #FFF;
                font-size: 45px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-transform: uppercase;
           
                margin-bottom: 23px;
            }
        }
    }
    .mainparentcards{
     
        .item{
    
            h4{
                color: #9A9A9A;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                text-transform: uppercase;
                margin: 15px 0px;
            }
            p{
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
                max-width: 405px;
                width: 100%;
             
            }
            .mainimagessss{
                max-width: 406px;
                max-height: 191.52px;
                object-fit: cover;
                object-position: center;
                overflow: hidden;
                .innerimage{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
    }
}

@media (max-width:600px){
    .mainblog{
        padding-bottom: 40px;
    }
    .mainviedo{
        padding-bottom: 40px !important;
    }
    .mainacademy .parentheadssss p{
        text-align: center;
        font-size: 14px !important;
    }
}