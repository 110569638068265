// .roadmap-section {
//     .parent {
//         display: grid;
//         grid-template-columns: 1fr 1fr;
//         height: 100vh;

//         .left-side {
//             position: relative;
//             padding-top: 262px;
//             padding-bottom: 398px;
//             padding-left: 110px;

//             .blacksidebg {
//                 position: absolute;
//                 top: 0;
//                 left: 0;
//                 width: 100%;
//                 height: 100%;
//                 object-fit: cover;
//                 z-index: -1;
//                 pointer-events: none;
//             }

//             .main-content {
//                 max-width: 494px;
//                 width: 100%;

//                 h5 {
//                     color: #FFF;
//                     font-size: 40px;
//                     font-style: normal;
//                     font-weight: 700;
//                     line-height: 120%;
//                     text-transform: uppercase;
//                     margin-bottom: 16px;
//                 }

//                 p {
//                     color: #FFF;
//                     font-size: 14px;
//                     font-style: normal;
//                     font-weight: 400;
//                     line-height: 120%;
//                     margin-bottom: 36px;
//                 }


//                 .btn-view {
//                     border: 1px solid #FFF;
//                     background: #000;
//                     display: flex;
//                     justify-content: space-between;
//                     align-items: center;
//                     max-width: 150px;
//                     width: 100%;

//                     h6 {
//                         border-left: 1px solid #FFF;
//                         background: #000;
//                         padding: 10px 36px;
//                         flex: 1;
//                         color: #FFF;
//                         font-size: 12px;
//                         font-style: normal;
//                         font-weight: 400;
//                         line-height: normal;
//                         text-transform: uppercase;

//                     }

//                     svg {
//                         min-width: 33px;
//                     }
//                 }
//             }
//         }

//         .right-side {
//             position: relative;
//             padding-top: 262px;
//             padding-bottom: 398px;
//             padding-left: 110px;

//             .whitesidebg {
//                 position: absolute;
//                 top: 0;
//                 left: 0;
//                 width: 100%;
//                 height: 100%;
//                 object-fit: cover;
//                 z-index: -1;
//                 pointer-events: none;
//             }

//             .main-content {
//                 max-width: 494px;
//                 width: 100%;

//                 h5 {
//                     color: #000;
//                     font-size: 40px;
//                     font-style: normal;
//                     font-weight: 700;
//                     line-height: 120%;
//                     text-transform: uppercase;
//                     margin-bottom: 16px;
//                 }

//                 p {
//                     color: #000;
//                     font-size: 14px;
//                     font-style: normal;
//                     font-weight: 400;
//                     line-height: 120%;
//                     margin-bottom: 36px;
//                 }


//                 .btn-view {
//                     border: 1px solid #000;
//                     background: #fff;
//                     display: flex;
//                     justify-content: space-between;
//                     align-items: center;
//                     max-width: 150px;
//                     width: 100%;

//                     h6 {
//                         border-right: 1px solid #000;
//                         background: #fff;
//                         padding: 10px 36px;
//                         flex: 1;
//                         color: #000;
//                         font-size: 12px;
//                         font-style: normal;
//                         font-weight: 400;
//                         line-height: normal;
//                         text-transform: uppercase;

//                     }

//                     svg {
//                         min-width: 33px;
//                     }
//                 }
//             }
//         }
//     }
// }

.protocol-section {
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    min-height: 100vh;


    .protocolbg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        pointer-events: none;
    }

    .parent {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 125px;

        .left-side {
            max-width: 677px;
            width: 100%;
            height: 100vh;
            overflow-y: auto;
            padding-bottom: 100px;

            &::-webkit-scrollbar {
                display: none;
            }


            .main-cards {
                .single-card {
                    border: 1px solid #4B4B4B;
                    background: #000;
                    padding: 20px;
                    margin-bottom: 24px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .top-area {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 20px;
                        margin-bottom: 20px;

                        h6 {
                            color: #FFF;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 120%;
                            text-transform: uppercase;
                        }
                    }

                    p {
                        color: #FFF;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                    }
                }

                .special-card {
                    .btn-view {
                        border: 1px solid #FFF;
                        background: #000;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        max-width: 180px;
                        width: 100%;
                        margin-top: 25px;

                        h6 {
                            border-right: 1px solid #FFF;
                            background: #000;
                            padding: 10px 36px;
                            flex: 1;
                            color: #FFF;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            text-transform: uppercase;

                        }

                        svg {
                            min-width: 33px;
                        }
                    }
                }

                .inside-cards {
                    border: 1px solid #1E1E1E;
                    background: #0B0B0B;
                    padding: 20px;
                    margin-top: 30px;

                    .single-card {
                        background-color: transparent;
                        border: none;
                        padding: 20px 0;
                    }

                }
            }

        }

        .right-side {
            flex: 1;
            position: sticky;
            top: 20px;
            padding-top: 193px;

            h5 {
                color: #FFF;
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-transform: uppercase;
                margin-bottom: 16px;
            }

            p {
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                margin-bottom: 36px;
            }


            .btn-view {
                border: 1px solid #FFF;
                background: #000;
                display: flex;
                justify-content: space-between;
                align-items: center;
                max-width: 150px;
                width: 100%;

                h6 {
                    border-left: 1px solid #FFF;
                    background: #000;
                    padding: 10px 36px;
                    flex: 1;
                    color: #FFF;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: uppercase;

                }

                svg {
                    min-width: 33px;
                }
            }

        }
    }
}




.eco-section {
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    min-height: 100vh;

    .protocolbg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        pointer-events: none;

    }

    .parent {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 125px;

        .left-side {
            max-width: 677px;
            width: 100%;
            height: 100vh;
            overflow-y: auto;
            padding-bottom: 100px;

            &::-webkit-scrollbar {
                display: none;
            }


            .main-cards {
                .single-card {
                    border: 1px solid #BABABA;
                    background: #FFF;
                    padding: 20px;
                    margin-bottom: 24px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .top-area {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 20px;
                        margin-bottom: 20px;

                        h6 {
                            color: #000;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 120%;
                            text-transform: uppercase;
                        }
                    }

                    p {
                        color: #000;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                    }
                }




            }

        }

        .right-side {
            flex: 1;
            position: sticky;
            top: 20px;
            padding-top: 193px;

            h5 {
                color: #000;
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-transform: uppercase;
                margin-bottom: 16px;
            }

            p {
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                margin-bottom: 36px;
            }


            .btn-view {
                border: 1px solid #000;
                background: #fff;
                display: flex;
                justify-content: space-between;
                align-items: center;
                max-width: 150px;
                width: 100%;

                h6 {
                    border-left: 1px solid #000;
                    background: #fff;
                    padding: 10px 36px;
                    flex: 1;
                    color: #000;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: uppercase;

                }

                svg {
                    min-width: 33px;
                }
            }

        }
    }
}



.roadmap-section {
    overflow: hidden;

    .containerrr {
        display: flex;
        width: 200%;
        height: 100%;
        transition: transform 0.5s ease-in-out;
        transform: translateX(-25%);
    }

    .activepage1 {
        transform: translateX(-50%);
        .left-side{
            opacity: 1 !important;
            transition: opacity 0.7s linear;
        }
        
        .eco-section  .right-side{
            padding-left: 0px !important;
        }
    }

    .activepage2 {
        transform: translateX(0);
        .left-side{
            opacity: 1 !important;
            transition: opacity 0.7s linear;
        }
        
        
    }

    .pagee {
        width: 50%;
        height: 100vh;
        position: relative;
        .eco-section  .right-side{
            transition: padding 0.3s linear;
        }
       .left-side{
        opacity: 0;
        transition: opacity 0.3s linear;
       }
    }


    .pagee1 {
        left: 0;
    }


    .pagee2 {
        right: 0;
    }


}

.wrapper-roadmap {
    transition: transform 0.7s ease-in-out;
    transform: translateX(0%);
}

.wrapper-roadmapactive {
    transform: translateX(-50%);
}

.wrapper-roadmapactive1 {
    transform: translateX(50%);
}

.roadmap-offcanvas {
    transition: transform 0.7s ease-in-out !important;
    height: 100vh !important;

    .main-navbar {
        width: 100%;
    }

    .offcanvas-header {
        padding: 0;

        .btn-close {
            display: none;
        }
    }

    .offcanvas-body {
        padding: 25px 0px 20px 0px;
        position: relative;

        .offcanvas-blackbg {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            pointer-events: none;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .upper-heading {
            margin-bottom: 40px;
            padding-right: 20px;
            padding-left: 20px;

            h4 {
                color: #FFF;
                text-align: center;
                font-size: 26px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-transform: uppercase;
                margin-bottom: 22px;
            }

            p {
                color: #FFF;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
            }
        }

        .bottom-cards {
            .main-cards {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;
                overflow-x: auto;

                &::-webkit-scrollbar {
                    display: none;
                }

                scroll-snap-type: x mandatory;
                scroll-behavior: smooth;

                .single-card {
                    border: 1px solid #4B4B4B;
                    background: #000;
                    padding: 20px;
                    margin-bottom: 24px;
                    flex: 0 0 340px;
                    min-height: 315px;
                    scroll-snap-align: center;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .top-area {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 20px;
                        margin-bottom: 20px;

                        h6 {
                            color: #FFF;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 120%;
                            text-transform: uppercase;
                        }
                    }

                    p {
                        color: #FFF;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                    }

                    .btn-view {
                        border: 1px solid #FFF;
                        background: #000;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        max-width: 180px;
                        width: 100%;
                        margin-top: 25px;

                        h6 {
                            border-right: 1px solid #FFF;
                            background: #000;
                            padding: 10px 36px;
                            flex: 1;
                            color: #FFF;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            text-transform: uppercase;

                        }

                        svg {
                            min-width: 33px;
                        }
                    }
                }

                .special-card {
                    .btn-view {
                        border: 1px solid #FFF;
                        background: #000;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        max-width: 180px;
                        width: 100%;
                        margin-top: 25px;

                        h6 {
                            border-right: 1px solid #FFF;
                            background: #000;
                            padding: 10px 36px;
                            flex: 1;
                            color: #FFF;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            text-transform: uppercase;

                        }

                        svg {
                            min-width: 33px;
                        }
                    }
                }

                .inside-cards {
                    border: 1px solid #1E1E1E;
                    background: #0B0B0B;
                    padding: 20px;
                    margin-top: 30px;

                    .single-card {
                        background-color: transparent;
                        border: none;
                        padding: 20px 0;
                    }

                }
            }
        }

        .bottom-abs-btn {
            position: fixed;
            bottom: 0;
            left: 0;
            color: #000;
            text-align: center;
            font-size: 26px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 9px;
            width: 100%;
            padding: 20px 0;
            background: url("../../assets/bgwhite.png");
        }

    }
}

.roadmap-offcanvas2 {
    transition: transform 1s ease-in-out !important;
    height: 100vh !important;

    .main-navbar {
        width: 100%;
    }

    .offcanvas-header {
        padding: 0;

        .btn-close {
            display: none;
        }
    }

    .offcanvas-body {
        padding: 100px 0px 20px 0px;
        position: relative;

        .offcanvas-blackbg {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            pointer-events: none;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .upper-heading {
            margin-bottom: 40px;
            padding-right: 20px;
            padding-left: 20px;

            h4 {
                color: #000;
                text-align: center;
                font-size: 26px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-transform: uppercase;
                margin-bottom: 22px;
            }

            p {
                color: #000;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
            }
        }

        .bottom-cards {
            .main-cards {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;
                overflow-x: auto;

                &::-webkit-scrollbar {
                    display: none;
                }

                scroll-snap-type: x mandatory;
                scroll-behavior: smooth;

                .single-card {
                    border: 1px solid #BABABA;
                    background: #fff;
                    padding: 20px;
                    margin-bottom: 24px;
                    flex: 0 0 340px;
                    min-height: 315px;
                    scroll-snap-align: center;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .top-area {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 20px;
                        margin-bottom: 20px;

                        h6 {
                            color: #000;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 120%;
                            text-transform: uppercase;
                        }
                    }

                    p {
                        color: #000;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                    }

             
                }
            }
        }

        .bottom-abs-btn {
            position: fixed;
            top: 68px;
            left: 0;
            color: #fff;
            text-align: center;
            font-size: 26px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 9px;
            width: 100%;
            padding: 20px 0;
            background: url("../../assets/bgblack.png");
        }

    }
}



.mobile-roadmap {
    height: calc(100vh - 69px);
    overflow: hidden;

    .parent {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
       

        .upper-side {
            position: relative;
            flex: 0 0 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;


            .mobile-blackimg {
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                pointer-events: none;
                width: 100%;
            }

            .main-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0 20px;

                h5 {
                    color: #FFF;
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    text-transform: uppercase;
                    margin-bottom: 16px;
                    text-align: center;
                }

                p {
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    margin-bottom: 36px;
                    text-align: center;
                }


                .btn-view {
                    border: 1px solid #FFF;
                    background: #000;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    max-width: 150px;
                    width: 100%;

                    h6 {
                        border-left: 1px solid #FFF;
                        background: #000;
                        padding: 10px 36px;
                        flex: 1;
                        color: #FFF;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: uppercase;

                    }

                    svg {
                        min-width: 33px;
                    }
                }
            }
        }

        .bottom-side {
            position: relative;
            flex: 0 0 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;


            .mobile-whitebg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                z-index: -1;
                pointer-events: none;
            }

            .main-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0 20px;

                h5 {
                    color: #000;
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    text-transform: uppercase;
                    margin-bottom: 16px;
                    text-align: center;
                }

                p {
                    color: #000;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    margin-bottom: 36px;
                    text-align: center;
                }


                .btn-view {
                    border: 1px solid #000;
                    background: #fff;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    max-width: 150px;
                    width: 100%;

                    h6 {
                        border-left: 1px solid #000;
                        background: #fff;
                        padding: 10px 36px;
                        flex: 1;
                        color: #000;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: uppercase;

                    }

                    svg {
                        min-width: 33px;
                    }
                }
            }
        }
    }
}


@media (max-width:600px) {
    .desktop-roadmap {
        display: none;
    }
    .mobile-roadmap{
        display: block !important;
    }
}