.joinus {
    padding: 64px 0px 81px;
    background: #0B0B0B;
    position: relative;

    .joinussvg {
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 410px;
        height: 380px;
    }

    .mainheadingsss {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;

        .joinhead {
            color: #FFF;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            text-transform: uppercase;
            margin-bottom: 49px;
            max-width: 649.498px;
            width: 100%;
        }

        .btn-launch {
            width: 141px;
        }

        h6 {
            white-space: nowrap;
        }

        svg {
            width: 41px;
        }
    }
}

@media(max-width:600px){
    .joinus .joinussvg{
        width: 186.733px;
height: 184px;
    }
    .joinus .mainheadingsss .joinhead{
        font-size: 20px !important;
    }
    .joinus{
       padding: 40px 20px;
    }
}