.maincompany {
    //     background: url(../../../src/assestsss/bg-company.png);
    //     background-repeat: no-repeat;
    //     background-position: center;
    //     background-size: cover;
    //     height: 464px;
    //    position: relative;
    //    margin-bottom: 20px;
    //     .mainhead{
    //         color: #FFF;
    //         font-size: 84px;
    //         font-style: normal;
    //         font-weight: 700;
    //         line-height: 120%; /* 100.8px */
    //         text-transform: uppercase;
    //         position: absolute;
    //         bottom: 50px;
    //         left: 42px;
    //         // padding-top: 258px;
    //     }

    background: url('../../assets/bgtech.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 464px;
    margin-bottom: 15px;

    .techbannerinner {
        position: relative;
        width: 100%;
        height: 464px;

        .techhead {
            color: #FFF;
            font-size: 84px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            position: absolute;
            bottom: 35px;
            left: 0px;
        }
    }
   
}

.theteam {
    background: #FFF;
    position: relative;
    z-index: 99;
    padding-bottom: 161px;
    margin-bottom: 15px;

    .top-left-text {
        border-bottom: 1px solid #DFDFDF;

        background: #FFF;

        padding-left: 30px;
        height: 69px;
        display: flex;
        align-items: center;
        margin-bottom: 68px;

        p {
            color: #7E7E7E;
            ;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            /* 16.8px */
            letter-spacing: -0.014px;
            text-transform: uppercase;
        }
    }

    .communitymaincards {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 20px;
        overflow: hidden;
        overflow-x: auto;
        padding-bottom: 90px;

        &::-webkit-scrollbar {
            display: none;
        }

        .communitycard {
            .imgone {
                min-width: 311px;
                height: 232px;
            }

            .imgtwo {
                min-width: 311px;
                height: 311px;
            }

            .imgthree {
                min-width: 312px;
                height: 249px;
            }

            .imgfour {
                min-width: 311px;
                height: 232px;
            }

            .imgfive {
                min-width: 386.868px;
                height: 308px;
            }

            .communitycardimg {
                display: flex;
                justify-content: center;
                align-items: center;
                // border: 1px solid #FFF;
                // background: #0B0B0B;
                // box-shadow: 3px 3px 0px 0px #FFF;
                object-fit: cover;
                object-position: center;
                height: 309px;

                .communitycardimg {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }

            .communitycardhead {
                color: #FFF;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                text-transform: uppercase;
                margin-top: 16px;
            }
        }
    }

    .parenttext {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 70px;
        margin-bottom: 90px !important;

        .left {

            p {
                color: #000;
                font-size: 25px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                border-left: 1px solid #000;
                padding-left: 20px;
                max-width: 613px;
            }
        }

        .right {

            p {
                color: #494949;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
                border-left: 1px solid transparent;
                padding-left: 20px;
                max-width: 613px;
            }
        }
    }

    .mainparentcards {
        margin-bottom: 90px;
    }

    .parenttexts {
        display: flex;
        justify-content: flex-start;
        gap: 101px;
        margin-bottom: 90px;

        .left {
            p {
                color: #494949;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                max-width: 573px;

            }
        }

        .right {
            .mainimg {
                max-width: 622px;
                max-height: 356px;
                object-fit: cover;
                object-position: center;
                overflow: hidden;

                .images {
                    width: 100% !important;
                    height: 100% !important;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
    }
}

.joinus {
    padding: 64px 0px 81px;
    background: #0B0B0B;
    position: relative;

    .joinussvg {
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 410px;
        height: 380px;
    }

    .mainheadingsss {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;

        .joinhead {
            color: #FFF;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            text-transform: uppercase;
            margin-bottom: 49px;
            max-width: 649.498px;
            width: 100%;
        }

        .btn-launch {
            width: 141px;
        }

        h6 {
            white-space: nowrap;
        }

        svg {
            width: 41px;
        }
    }
}

@media(max-width:992px) {
    @media (max-width:992px) {
        .techbanner .techbannerinner .techhead {
            font-size: 49px;
            bottom: 30px;
        }

        .techbanner {
            height: 366px;
            // background: url('../../../assets/bgtechmbl.png');
            background: url(../../assets/bgtechmbl.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }

        .techbanner .techbannerinner {
            height: 366px;
        }
    }

    .theteam .parenttext {
        flex-direction: column;
        gap: 40px;
    }

    .joinus .mainheadingsss .joinhead {
        font-size: 30px;
    }

    .theteam .parenttext .right p {
        font-size: 16px;
    }

    .theteam .parenttext .left p {
        font-size: 20px;
    }

    .mainteam .mainhead {
        font-size: 49px;
    }

    .mainteam .mainhead {
        padding-top: 351px;
    }

    .theteam .parenttexts {
        flex-direction: column-reverse;
        gap: 70px;
    }

    .theteam .parenttexts .left p {
        font-size: 16px;
    }

    .theteam .communitymaincards {
        padding-bottom: 70px;
    }

    .theteam {
        padding-bottom: 0px;
    }

    .theteam .parenttext {
        margin-bottom: 0px !important;
        padding-bottom: 70px;
    }
}

@media (max-width:600px){
    .maincompany{
        height: 248px;
    }
    .maincompany .techbannerinner{
        height: 248px;
    }
}

.contactus-btn{
    max-width: 200px;
    margin-top: 25px;
    h6{
        width: 100% !important;
        text-align: center;
    }
}