.main-footer {
    margin-top: 20px;
    border: 1px solid #1B1B1B;
    background: #000;
    position: relative;

    .tppara {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;

        .innerpara {
            color: #9A9A9A;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
        }
    }

    .parent {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left-side {
            border-right: 1px solid #1B1B1B;
            background: #000;
            max-width: 377px;
            width: 100%;
            padding-top: 50px;
            padding-bottom: 34px;
            transition: background-color 0.3s ease;

            p {
                color: #9A9A9A;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                max-width: 258px;
                margin-top: 30px;
            }

            .right-text {
                margin-top: 89px;
            }
        }

        .right-side {
            flex: 1;

            .right-links {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                .links-text {
                    padding: 50px 43px;
                    position: relative;

                    h6 {
                        color: #9A9A9A;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                        text-transform: uppercase;
                        margin-bottom: 30px;
                    }

                    a {
                        display: block;
                        color: #FFF;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                        text-transform: uppercase;
                        margin-top: 15px;
                        transition: transform 0.3s ease;

                        &:hover {
                            transform: translateX(5px);
                        }
                    }
                }


            }
        }
    }

    .social-icons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        margin-top: 15px;
    }
}


@media (max-width:600px) {
    .main-footer .tppara{
        justify-content: center;
    }
    .main-footer .parent {
        flex-direction: column;
    }

    .main-footer .parent .right-side .right-links {
        flex-wrap: wrap;
    }

    .main-footer .parent .left-side {
        border-right: none;
    }

    .main-footer .parent .left-side .right-text {
        display: none;
    }

    .main-footer .parent .left-side {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        padding: 50px;
    }

    .main-footer .parent .left-side p {
        text-align: start;
    }

    .main-footer .parent .right-side .right-links .links-text {
        padding: 35px 10px;
    }

    .main-footer .parent .right-side .right-links .links-text a {
        text-align: start;
    }

    .main-footer .parent .right-side .right-links .links-text h6 {
        text-align: start;
    }

    .right-reserved-text {
        background: #000;
        padding: 28px;
        color: #9A9A9A;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-transform: capitalize;
        display: block !important;
        margin: 0 auto;
    }
}

@media (max-width:390px) {
    .main-footer .parent .right-side .right-links .links-text {
        padding: 35px 5px;
    }
}